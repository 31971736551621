import React, { useMemo } from "react";
import { ColumnsBlock } from "../../types/Block";
import { Wrapper } from "../Wrapper";
import { Column, Spacing } from "../../helpers/layout";
import { Image } from "../Image";
import { css } from "styled-components";
import { EditorContent } from "../EditorContent";
import { useResponsive } from "../../context/ResponsiveContext";

const Layout = (props: { children: React.ReactNode | React.ReactNode[] }) => {
  const { isMobile } = useResponsive();

  if (isMobile) {
    return (
      <Column
        gutter={Spacing.xxl}
        css={css`
          text-align: center;
        `}
      >
        {props.children}
      </Column>
    );
  }

  return (
    <div
      css={`
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: ${Spacing.xl};
      `}
    >
      {props.children}
    </div>
  );
};

export function ColumnsBlockRenderer(props: { block: ColumnsBlock }) {
  const columns = props.block.content.columns;
  const { isMobile, isTablet, is13inch } = useResponsive();

  const columnWidth = useMemo(() => {
    const cw = (cols: number) => {
      const c = Math.min(cols, columns.length);
      return `calc(100% / ${c} - (${Spacing.xl} * ${c - 1} / ${c}))`;
    };

    if (isMobile) {
      return "100%";
    }
    if (isTablet) {
      return cw(2);
    }
    if (is13inch) {
      return cw(4);
    }
    return cw(6);
  }, [columns.length, is13inch, isMobile, isTablet]);

  return (
    <div
      css={css`
        margin: ${Spacing.xxl} 0;
      `}
    >
      <Wrapper>
        <Layout>
          {columns.map((column, idx) => (
            <div
              key={idx}
              css={`
                width: ${columnWidth};
                min-width: 275px;
                max-width: 1200px;
              `}
            >
              <Column>
                {column.image && (
                  <Image
                    file={column.image.value}
                    width={500}
                    style={{ width: "100%" }}
                  />
                )}
                {column.text && (
                  <div
                    css={css`
                      margin-top: ${column.image && Spacing.l};
                    `}
                  >
                    <EditorContent text={column.text} />
                  </div>
                )}
              </Column>
            </div>
          ))}
        </Layout>
      </Wrapper>
    </div>
  );
}
