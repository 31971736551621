import React, { useRef } from "react";
import { SeriesBlock } from "../../types/Block";
import { Spacing, Row, Column } from "../../helpers/layout";
import ArrowLeft from "../../icons/ArrowLeft";
import ArrowRight from "../../icons/ArrowRight";
import styled, { css } from "styled-components";
import { Wrapper } from "../Wrapper";
import { MasonryGrid } from "../MasonryGrid";
import { Image } from "../Image";
import { Title, ItalicTitle } from "../../typography";
import { Button } from "../Button";
import Router from "next/router";
import { useResponsive } from "../../context/ResponsiveContext";
import Slider from "react-slick";

const Leftside = styled.div`
  flex: 0 0 30%;
`;
const Rightside = styled.div`
  flex: 1 0 30%;
`;

const SliderButton = styled.button`
  appearance: none;
  border: none;
  background: none;

  width: 1.5rem;
  height: 1.5rem;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export function SeriesBlockRenderer(props: { block: SeriesBlock }) {
  const series = props.block.content.series.value;
  const { isMobile, isTablet, is13inch } = useResponsive();
  const ref = useRef<any>(null);

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const title = (
    <Title>
      Explore{" "}
      <i
        style={{
          fontWeight: 200,
        }}
      >
        the
      </i>{" "}
      Artwork
    </Title>
  );

  const goNext = () => {
    if (!ref.current) return;

    ref.current.slickNext();
  };

  const goPrev = () => {
    if (!ref.current) return;

    ref.current.slickPrev();
  };

  return (
    <div
      css={css`
        margin: ${Spacing.xxl} 0;
      `}
    >
      <Wrapper>
        <Column gutter={Spacing.xl}>
          <div>
            {isMobile ? (
              <Row justify="space-between" align="center">
                {title}
                <div>
                  <Row>
                    <SliderButton
                      css={css`
                        left: 0;
                      `}
                      onClick={() => {
                        goPrev();
                      }}
                    >
                      <ArrowLeft />
                    </SliderButton>
                    <SliderButton
                      css={css`
                        right: 0;
                      `}
                      onClick={() => {
                        goNext();
                      }}
                    >
                      <ArrowRight />
                    </SliderButton>
                  </Row>
                </div>
              </Row>
            ) : (
              <Row gutter={Spacing.xxl}>
                <Leftside> </Leftside>
                <Rightside>{title}</Rightside>
              </Row>
            )}
          </div>
          <div>
            <Slider ref={ref} {...settings}>
              {series.map((serie, idx) => {
                const name = serie.name.split(": ");
                const controls = (
                  <Row align="center" justify="center">
                    <div>
                      <SliderButton
                        css={css`
                          left: 0;
                        `}
                        onClick={() => {
                          goPrev();
                        }}
                      >
                        <ArrowLeft />
                      </SliderButton>
                    </div>
                    <div
                      css={css`
                        font-size: 1rem;
                      `}
                    >
                      {idx + 1} / {series.length}
                    </div>
                    <div>
                      <SliderButton
                        css={css`
                          right: 0;
                        `}
                        onClick={() => {
                          goNext();
                        }}
                      >
                        <ArrowRight />
                      </SliderButton>
                    </div>
                  </Row>
                );

                const leftside = (
                  <Column>
                    {!isMobile && (
                      <div
                        css={css`
                          margin-bottom: ${Spacing.xl};
                        `}
                      >
                        {controls}
                      </div>
                    )}
                    <div>
                      <Title>
                        {name[0]}
                        {name[1] && ":"}
                      </Title>
                      {name[1] && <ItalicTitle>{name[1]}</ItalicTitle>}
                    </div>
                    <div>
                      <p>{serie.description}</p>
                    </div>
                    <div>
                      <Button
                        type="button"
                        label="View the Series"
                        onClick={() => {
                          Router.push(
                            "/series/[slug]",
                            `/series/${serie.slug}`
                          );
                        }}
                      />
                    </div>
                  </Column>
                );

                const items = isMobile
                  ? serie.products.slice(0, 4)
                  : serie.products;

                const rightside = (
                  <div
                    css={css`
                      position: relative;
                      height: 100%;
                      overflow: hidden;
                    `}
                  >
                    <MasonryGrid
                      columns={isTablet || isMobile || is13inch ? 2 : 3}
                      items={items}
                    >
                      {(item, index) => {
                        if (!item.featured_image) return null;

                        return (
                          <div
                            css={css`
                              margin-bottom: 2%;
                            `}
                            className="grid-item"
                            key={`masonry-item--${index}`}
                          >
                            <div
                              css={css`
                                position: relative;
                                width: 100%;
                                height: 0;
                                padding-bottom: ${(item.featured_image.height /
                                  item.featured_image.width) *
                                100}%;
                              `}
                            >
                              <Image
                                file={item.featured_image!}
                                width={isMobile ? 100 : 330}
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  top: 0,
                                  left: 0,
                                }}
                              />
                            </div>
                          </div>
                        );
                      }}
                    </MasonryGrid>
                    <div
                      css={css`
                        width: 100%;
                        height: 35%;
                        background: white;
                        position: absolute;
                        bottom: 0;
                        background: linear-gradient(
                          to bottom,
                          rgba(255, 255, 255, 0) 0%,
                          rgba(255, 255, 255, 1) 100%
                        );
                        pointer-events: none;
                      `}
                    ></div>
                  </div>
                );

                return (
                  <div key={`series-slide--${idx}`}>
                    {isMobile ? (
                      <Column>
                        <div
                          css={css`
                            height: 35vh;
                          `}
                        >
                          {rightside}
                        </div>
                        {leftside}
                      </Column>
                    ) : (
                      <Row
                        gutter={Spacing.xxl}
                        align="center"
                        css={css`
                          height: 100%;
                        `}
                      >
                        <Leftside
                          css={css`
                            text-align: center;
                            padding-bottom: ${Spacing.xxl};
                          `}
                        >
                          {leftside}
                        </Leftside>
                        <Rightside
                          css={css`
                            height: 60vh;
                          `}
                        >
                          {rightside}
                        </Rightside>
                      </Row>
                    )}
                  </div>
                );
              })}
            </Slider>
          </div>
        </Column>
      </Wrapper>
    </div>
  );
}
