import { Image } from "./Image";
import { css } from "styled-components";
import { Colors } from "../branding";
import { Press } from "../types/Press";
import { useResponsive } from "../context/ResponsiveContext";

export function PressItem(props: { item: Press }) {
  const item = props.item;
  const { isMobile } = useResponsive();
  const size = isMobile ? 100 : 170;

  return (
    <div
      css={css`
        flex: 0 0 200px;
        position: relative;
        text-align: center;
        cursor: pointer;

        .overlay {
          opacity: 0;
          visibility: hidden;
          background: ${Colors.white};
        }

        &:hover .overlay {
          opacity: 1;
          visibility: visible;
        }
      `}
    >
      <Image
        file={item.logo}
        style={{ maxHeight: size, maxWidth: size }}
        height={size}
      />
      <div
        className="overlay"
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        `}
      >
        <Image file={item.cover} style={{ width: "200px" }} height={200} />
      </div>
    </div>
  );
}
