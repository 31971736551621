import React, { useState } from "react";
import { InHomesBlock } from "../../types/Block";
import { Row, Spacing, Column } from "../../helpers/layout";
import { css } from "styled-components";
import { EditorContent } from "../EditorContent";
import { Scrollbar } from "../Scrollbar";
import { InHomeThumb } from "../InHomeThumb";
import { InHomeModal } from "../../modals/InHomeModal";
import { InHome } from "../../types/InHome";
import { useWrapper } from "../../helpers/useWrapper";
import { useResponsive } from "../../context/ResponsiveContext";

export function InHomesBlockRenderer(props: { block: InHomesBlock }) {
  const { in_homes, text } = props.block.content;
  const [openedInHome, setInHomeOpened] = useState<InHome | null>(null);
  const spacing = useWrapper();
  const { isMobile } = useResponsive();

  const items = isMobile ? in_homes.value.slice(0, 3) : in_homes.value;

  const leftside = <EditorContent text={text} responsive />;

  const rightside = (
    <Scrollbar autoHeight>
      <Row gutter={Spacing.xl}>
        {items.map((item) => {
          return (
            <div
              key={item.id}
              css={css`
                padding-bottom: ${Spacing.m};
              `}
            >
              <InHomeThumb
                height={isMobile ? 200 : 600}
                item={item}
                onClick={() => {
                  setInHomeOpened(item);
                }}
              />
            </div>
          );
        })}
      </Row>
    </Scrollbar>
  );

  return (
    <React.Fragment>
      <div
        css={css`
          margin: ${Spacing.xxl} 0;
        `}
      >
        <div
          css={css`
            padding-left: ${spacing};

            .Scrollbar__track {
              padding-right: ${spacing};
              box-sizing: border-box;
            }
          `}
        >
          {isMobile ? (
            <Column>
              <div
                css={css`
                  padding-right: ${spacing};
                  box-sizing: border-box;
                `}
              >
                {leftside}
              </div>
              <div>{rightside}</div>
            </Column>
          ) : (
            <Row justify="space-between" align="center" gutter={Spacing.xxl}>
              <div
                css={css`
                  flex: 0 0 30%;
                  min-width: 0;
                  max-width: 450px;
                `}
              >
                {leftside}
              </div>
              <div
                css={css`
                  flex: 1 0 20%;
                `}
              >
                {rightside}
              </div>
            </Row>
          )}
        </div>
      </div>
      {openedInHome && (
        <InHomeModal
          inHome={openedInHome}
          visible={true}
          onClose={() => {
            setInHomeOpened(null);
          }}
        />
      )}
    </React.Fragment>
  );
}
