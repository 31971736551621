import React from "react";
import { FullscreenVideoBlock } from "../../types/Block";
import { Video } from "../Video";
import { css } from "styled-components";
import ArrowDown from "../../icons/ArrowDown";
import { Spacing } from "../../helpers/layout";
import { Colors } from "../../branding";

export function FullscreenVideoBlockRenderer(props: {
  block: FullscreenVideoBlock;
}) {
  const content = props.block.content;

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: calc(100vh - 32px);
        overflow: hidden;

        .video-js .vjs-tech {
          object-fit: cover;
        }
        .video-js.vjs-fluid {
          padding-top: 0 !important;
          height: 100% !important;
        }
      `}
    >
      <Video
        fullscreen
        video={{
          desktop_id: content.desktop_id,
          tablet_id: content.tablet_id,
          mobile_id: content.mobile_id,
        }}
        hasSound={content.has_sound}
      />
      <div
        css={css`
          position: absolute;
          left: 0;
          right: 0;
          width: 50px;
          height: auto;
          bottom: ${Spacing.xl};
          margin: auto;
          text-align: center;
          cursor: pointer;
          animation-name: bouncing;
          animation-duration: 1s;
          animation-iteration-count: infinite;

          svg {
            width: 35px;
            fill: ${Colors.white};
          }
        `}
        onClick={() => {
          window.scrollTo({ top: window.innerHeight, behavior: "smooth" });
        }}
      >
        <ArrowDown />
      </div>
    </div>
  );
}
