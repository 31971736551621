import React, { useMemo, useState } from "react";
import { ProductsBlock } from "../../types/Block";
import { css } from "styled-components";
import { MasonryGrid } from "../MasonryGrid";
import { ProductThumb } from "../ProductThumb";
import { Wrapper } from "../Wrapper";
import { Spacing } from "../../helpers/layout";
import { useResponsive } from "../../context/ResponsiveContext";
import { ProductsGrid } from "../ProductsGrid";
import { Product } from "../../types/Product";
import { QuickShopProductModal } from "../../modals/QuickProductModal";

export function ProductsBlockRenderer(props: { block: ProductsBlock }) {
  const { layout, products, layout_style } = props.block.content;
  const { isMobile, isTablet } = useResponsive();
  const [previewProduct, setPreviewProduct] = useState<Product | undefined>();

  const handleProductPreview = (product: Product) => {
    setPreviewProduct(product);
  };

  const columns = useMemo(() => {
    if (isMobile) {
      return 1;
    }
    if (isTablet || layout_style === "wide") {
      return 2;
    }

    return 3;
  }, [isMobile, isTablet, layout_style]);

  const isWideLayout = layout_style === "wide";

  return (
    <div
      css={css`
        padding: ${Spacing.xxl} 0;
      `}
    >
      <Wrapper type={layout === "grid" ? undefined : "header"}>
        {layout === "grid" && (
          <ProductsGrid
            products={products.value}
            columns={columns}
            onQuickShop={(product) => handleProductPreview(product)}
          />
        )}
        {layout === "masonry" && (
          <MasonryGrid columns={columns} items={products.value}>
            {(product, index) => {
              let isFullWidth = false;
              if (product.featured_image) {
                const aspectRatio =
                  product.featured_image.width / product.featured_image.height;
                isFullWidth = aspectRatio > 2;
              }
              return (
                <div
                  className={`grid-item ${
                    isWideLayout && isFullWidth ? "grid-item--fullwidth" : ""
                  }`}
                  style={{
                    marginBottom: isMobile ? "5%" : "2%",
                  }}
                  key={`masonry-item--${index}`}
                >
                  <ProductThumb
                    key={product.id}
                    product={product}
                    enableOverlay
                    onQuickShop={() => handleProductPreview(product)}
                    previewWidth={columns === 2 ? "large" : undefined}
                  />
                </div>
              );
            }}
          </MasonryGrid>
        )}
      </Wrapper>
      <QuickShopProductModal
        visible={!!previewProduct}
        onClose={() => setPreviewProduct(undefined)}
        product={previewProduct}
      />
    </div>
  );
}
