import React, { useState } from "react";
import { DesignersBlock, DesignerItem } from "../../types/Block";
import { Image } from "../Image";
import { css } from "styled-components";
import { Title, ItalicTitle } from "../../typography";
import { Column, Row, Spacing } from "../../helpers/layout";
import { Button } from "../Button";
import { useResponsive } from "../../context/ResponsiveContext";
import { Wrapper } from "../Wrapper";
import { MOBILE } from "../../branding";

const ImageStyles = {
  maxWidth: "200px",
  maxHeight: "100px",
  width: "auto",
  height: "auto",
};

export function DesignersBlockRenderer(props: { block: DesignersBlock }) {
  const { title, description, designers, padding } = props.block.content;
  const [showAll, setShowAll] = useState(false);
  const { renderPadding, isMobile } = useResponsive();

  return (
    <Wrapper>
      <div
        css={css`
          ${padding && renderPadding(padding)};
        `}
      >
        <Column
          gutter={Spacing.xxl}
          css={css`
            text-align: center;
          `}
        >
          <div>
            <Title
              css={css`
                font-size: 72px;

                @media screen and (max-width: ${MOBILE + 1}px) {
                  font-size: 40px;
                }
              `}
            >
              {title}
            </Title>
            <ItalicTitle
              css={css`
                font-size: 20px;
                margin-top: ${Spacing.l};
              `}
            >
              {description}
            </ItalicTitle>
          </div>
          <div
            css={css`
              position: relative;
              transition: max-height 0.8s ease-in;
              overflow: hidden;
              max-height: ${isMobile ? 300 : 240}px;
              ${showAll &&
              css`
                max-height: ${isMobile ? 4000 : 2000}px;
                height: auto;
              `}
            `}
          >
            <Row
              gutter={Spacing.xl}
              align="center"
              wrap="wrap"
              justify="center"
            >
              {designers.map((designer: DesignerItem, ind) => {
                return (
                  <div
                    key={`image--${ind}`}
                    css={css`
                      flex: 0 0 auto;
                      margin-bottom: ${Spacing.xl};
                    `}
                  >
                    {designer.url ? (
                      <a key={ind} href={`${designer.url}`} target="_blank">
                        <Image
                          file={designer.logo ? designer.logo.value : null}
                          height={100}
                          style={ImageStyles}
                        />
                      </a>
                    ) : (
                      <Image
                        file={designer.logo ? designer.logo.value : null}
                        height={100}
                        style={ImageStyles}
                      />
                    )}
                  </div>
                );
              })}
            </Row>
            {!showAll && (
              <div
                css={css`
                  width: 100%;
                  height: 200px;
                  background: white;
                  position: absolute;
                  bottom: 0;
                  background: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 1) 100%
                  );
                  pointer-events: none;
                `}
              ></div>
            )}
          </div>
          {!showAll && (
            <div>
              <Button
                type="button"
                label="See More"
                onClick={() => {
                  setShowAll(true);
                }}
              />
            </div>
          )}
        </Column>
      </div>
    </Wrapper>
  );
}
