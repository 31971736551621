import React, { useEffect, useRef, useState } from "react";
import { PlainButton } from "./PlainButton";
import { css } from "styled-components";
import SoundOff from "../icons/SoundOff";
import SoundOn from "../icons/SoundOn";
import { Colors } from "../branding";
import { Spacing } from "../helpers/layout";
import { useResponsive } from "../context/ResponsiveContext";
import videojs from "video.js";
import "video.js/dist/video-js.css";
declare global {
  interface Window {
    __stream: any;
  }
}

export function Video(props: {
  video: {
    desktop_id: string;
    mobile_id: string;
    tablet_id: string;
  };
  hasSound: boolean;
  fullscreen?: boolean;
  opacity?: string;
}) {
  const videoContainerRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<any>(null);
  const [muted, setMuted] = useState(true);
  const { isMobile, isTablet } = useResponsive();
  const ref = useRef<HTMLDivElement>(null);
  const { fullscreen } = props;

  useEffect(() => {
    const id = isMobile
      ? props.video.mobile_id
      : isTablet
      ? props.video.tablet_id
      : props.video.desktop_id;

    const url = `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}/${id}/manifest/video.m3u8`;
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("vjs-big-play-centered");

      videoContainerRef.current?.appendChild(videoElement);

      playerRef.current = videojs(
        videoElement,
        {
          autoplay: true,
          muted: true,
          controls: false,
          loop: true,
          width: "100%",
          height: fullscreen ? "100%" : undefined,
          fluid: true,
          responsive: true,
          playsinline: true,
          sources: [
            {
              src: url,
              type: "application/x-mpegURL",
            },
          ],
        },
        () => {
          // callback player ready
        }
      );

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      if (player) {
        player.src([
          {
            src: url,
            type: "application/x-mpegURL",
          },
        ]);
        player.playsinline(true);
        player.autoplay(true);
      }
    }

    return () => {
      const player = playerRef.current;
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [
    isMobile,
    isTablet,
    videoContainerRef,
    playerRef,
    fullscreen,
    props.video,
  ]);
  return (
    <div
      className="video-wrapper"
      css={css`
        position: relative;
        ${props.fullscreen &&
        css`
          height: 100%;
        `}
      `}
      ref={ref}
    >
      <div
        data-vjs-player
        css={css`
          opacity: ${props.opacity || 1};
          width: 100%;
          background: ${Colors.black};

          ${props.fullscreen &&
          css`
            height: 100%;
          `}
        `}
      >
        <div
          css={css`
            object-fit: cover;
            height: 100%;
          `}
          ref={videoContainerRef}
        />
      </div>

      {props.hasSound && (
        <PlainButton
          onClick={() => {
            setMuted((state) => !state);
          }}
          css={css`
            position: absolute;
            bottom: ${Spacing.xl};
            left: ${Spacing.xl};

            svg {
              fill: ${Colors.white};
            }
          `}
        >
          {muted ? <SoundOff /> : <SoundOn />}
        </PlainButton>
      )}
    </div>
  );
}
