import React from "react";
import { VideoPlayer } from "../components/VideoPlayer";
import { Colors } from "../branding";
import { Row, Spacing, Column } from "../helpers/layout";
import { css } from "styled-components";
import { BaseModal } from "../modals/BaseModal";
import { Button } from "../components/Button";
import { useResponsive } from "../context/ResponsiveContext";

export function VideoModal(props: {
  visible: boolean;
  onClose: () => void;
  video: {
    url: string;
    description: string;
  };
  button: {
    label: string | null;
    url: string | null;
  };
  muted: boolean;
}) {
  const { isMobile } = useResponsive();
  const Layout = isMobile ? Column : Row;

  return (
    <BaseModal visible={props.visible} onClose={props.onClose}>
      <VideoPlayer url={props.video.url} autoPlay muted={props.muted} />
      <div
        css={css`
          margin-top: ${Spacing.m};
        `}
      >
        <Layout justify="space-between">
          <div
            css={css`
              border-left: 1px solid ${Colors.white};
              padding-left: ${Spacing.m};
              flex: 0 1 70%;
              font-size: ${isMobile ? "12px" : "16px"};
              line-height: ${isMobile ? "17px" : "27px"};
              box-sizing: border-box;
            `}
          >
            {props.video.description}
          </div>
          {props.button.label && props.button.url && (
            <div
              css={css`
                text-align: ${isMobile && "center"};
              `}
            >
              <Button
                type="button"
                style="white-border"
                label={props.button.label}
                onClick={() => {
                  if (props.button.url) {
                    window.location.href = props.button.url;
                  }
                }}
              />
            </div>
          )}
        </Layout>
      </div>
    </BaseModal>
  );
}
