const SoundOn = () => (
  <svg width="27px" height="26px" viewBox="0 0 27 26">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M16.2081,0.2037 C15.9585,0.0801 15.6609,0.1104 15.4407,0.2796 L6.1392,7.4313 L0.7398,7.4313 C0.3369,7.4313 0.0099,7.758 0.0099,8.1612 L0.0099,17.0394 C0.0099,17.4426 0.3366,17.7693 0.7398,17.7693 L6.1392,17.7693 L15.4416,24.921 C15.5715,25.0209 15.7281,25.0722 15.8862,25.0722 C15.996,25.0722 16.1064,25.0476 16.2084,24.9969 C16.4577,24.8739 16.6158,24.6201 16.6158,24.3423 L16.6152,0.8583 C16.6155,0.5805 16.4574,0.3261 16.2081,0.2037 Z M6.8319,16.4604 C6.7047,16.3626 6.5481,16.3095 6.387,16.3095 L1.4697,16.3095 L1.4697,8.8908 L6.3873,8.8908 C6.5484,8.8908 6.705,8.8377 6.8325,8.7399 L15.156,2.34 L15.1566,22.8603 L6.8319,16.4604 Z"
        fill="#fff"
      ></path>
      <path
        d="M22.713,2.2575 C22.428,1.9722 21.9657,1.9722 21.681,2.2575 C21.396,2.5425 21.396,3.0045 21.681,3.2895 C26.8149,8.4234 26.8149,16.7772 21.681,21.9111 C21.396,22.1964 21.396,22.6581 21.681,22.9431 C21.8238,23.0859 22.0104,23.157 22.197,23.157 C22.3839,23.157 22.5705,23.0859 22.7133,22.9431 C28.4157,17.2401 28.4157,7.9605 22.713,2.2575 Z"
        fill="#fff"
      ></path>
      <path
        d="M19.7652,5.2056 C19.4799,4.9206 19.0179,4.9206 18.7329,5.2056 C18.4482,5.4909 18.4482,5.9529 18.7332,6.2376 C20.4324,7.9371 21.3684,10.1964 21.3684,12.6 C21.3684,15.0036 20.4324,17.2632 18.7332,18.9627 C18.4482,19.2477 18.4482,19.7097 18.7332,19.9947 C18.8757,20.1372 19.0626,20.2086 19.2492,20.2086 C19.4361,20.2086 19.6227,20.1372 19.7655,19.9947 C21.7404,18.0195 22.8285,15.393 22.8285,12.6 C22.8282,9.8067 21.7401,7.1808 19.7652,5.2056 Z"
        fill="#fff"
      ></path>
    </g>
  </svg>
);
export default SoundOn;
