import { useState } from "react";
import { ContactFormValues, initialValues, contactFormSchema } from "./utils";

import { Formik, Form, FormikConfig } from "formik";
import { useRouter } from "next/router";
import { notAuthorised } from "request";
import { Spacing, Column, Row } from "helpers/layout";
import ContactFormField from "./ContactFormField";
import { Button } from "components/Button";
import { css } from "styled-components";
import { Message } from "components/Message";
import { gtag, gtagSetUser } from "helpers/gtag";
import EventsTracker from "helpers/fb";

export default function ContactForm({
  submitLabel,
  uuid,
  redirectUrl
}: {
  submitLabel: string;
  uuid: string;
  redirectUrl: string | null;
}) {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const router = useRouter();
  const hasSlug = router.pathname === "/[slug]" && !!router.query.slug;

  if (!hasSlug) return null;

  function sendAnalyticsEvents(values: ContactFormValues) {
    const payload = {
      email: EventsTracker.hash(values.email),
      firstName: EventsTracker.hash(values.first_name),
      lastName: EventsTracker.hash(values.last_name)
    };

    gtagSetUser(payload);
    gtag("contact_form_message", payload);
    EventsTracker.trackCustom("contact_form_message", payload);
  }

  const handleSubmit: FormikConfig<ContactFormValues>["onSubmit"] = async (
    values,
    { setSubmitting, resetForm }
  ) => {
    setSubmitting(true);
    setSuccessMessage(null);
    setErrorMessage(null);

    const data = { page: router.query.slug, form: uuid, user: values };
    const res = await notAuthorised
      .post<{ message: string }>("/api/frontend/contact/", data)
      .catch((err) => ({
        status: err.response.status,
        data: { message: "Something went wrong." }
      }));

    if (res.status === 200) {
      resetForm();
      sendAnalyticsEvents(values);
      if (redirectUrl) {
        window.location.assign(redirectUrl);
      } else {
        setSuccessMessage(res?.data?.message || "Message Sent!");
      }
    } else {
      setErrorMessage(res?.data?.message || "Something went wrong.");
    }
    setSubmitting(false);
  };

  return (
    <Formik<ContactFormValues>
      initialValues={initialValues}
      validationSchema={contactFormSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Column
            gutter={Spacing.l}
            css={css`
              position: relative;
            `}
          >
            <Row>
              <ContactFormField name="first_name" placeholder="First Name" />
              <ContactFormField
                name="last_name"
                type="text"
                placeholder="Last Name"
              />
            </Row>

            <ContactFormField name="email" type="email" placeholder="Email" />
            <ContactFormField name="message" placeholder="Message" />

            <Button
              isSubmitting={isSubmitting}
              fullwidth
              type="submit"
              label={isSubmitting ? "Sending..." : submitLabel}
            />
            {errorMessage || successMessage ? (
              <div
                css={css`
                  display: flex;
                  justify-content: center;

                  @media only screen and (min-width: 768px) {
                    position: absolute;
                    width: 100%;
                    left: 0;
                    bottom: -35px;
                    top: auto;
                  }
                `}
              >
                {errorMessage && <Message text={errorMessage} />}
                {successMessage && <Message text={successMessage} success />}
              </div>
            ) : null}
          </Column>
        </Form>
      )}
    </Formik>
  );
}
