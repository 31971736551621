import React, { useRef, useEffect, useState } from "react";
import { CanvasBlock, BlockVisibility } from "../../types/Block";
import { useVisibility } from "../../helpers/useVisibility";
import { useResponsive } from "../../context/ResponsiveContext";
import { css } from "styled-components";
import { CanvasTextParallax } from "./Canvas/TextParallax";
import { CanvasImageParallax } from "./Canvas/ImageParallax";
import { CanvasDefault } from "./Canvas/Default";
import { getImageUrl } from "../../helpers/getImageUrl";
import { MobileCanvas } from "./Canvas/MobileCanvas";

export function CanvasBlockRenderer(props: {
  block: CanvasBlock & {
    visibility: BlockVisibility;
  };
}) {
  const { padding, parallax, image } = props.block.content;
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const visibility = useVisibility(ref);
  const { getFontSize, isMobile, renderPadding } = useResponsive();
  const imageUrl = getImageUrl(image.value, {
    width: "large",
  });
  const proportion = image.value.height / image.value.width;
  const enableMobileLayout = props.block.visibility === "all";
  const isHidden = props.block.visibility === "none";

  useEffect(() => {
    if (visibility.isVisible) {
      setIsVisible(true);
    }
  }, [visibility.isVisible]);

  if (!proportion || isHidden) return null;

  if (enableMobileLayout && isMobile) {
    return (
      <MobileCanvas canvas={props.block.content} proportion={proportion} />
    );
  }

  return (
    <div
      ref={ref}
      css={css`
        ${padding && renderPadding(padding)};

        ${(parallax === "image" || parallax === "") &&
        css`
          overflow: hidden;
        `}

        p {
          font-size: ${getFontSize(16)}px;
        }
      `}
    >
      {parallax === "text" ? (
        <CanvasTextParallax
          canvas={props.block.content}
          isVisible={isVisible}
          visibility={visibility}
          imageUrl={imageUrl}
          proportion={proportion}
        />
      ) : parallax === "image" ? (
        <CanvasImageParallax
          canvas={props.block.content}
          isVisible={isVisible}
          visibility={visibility}
          imageUrl={imageUrl}
          proportion={proportion}
        />
      ) : (
        <CanvasDefault
          canvas={props.block.content}
          isVisible={isVisible}
          visibility={visibility}
          imageUrl={imageUrl}
          proportion={proportion}
        />
      )}
    </div>
  );
}
