import { Input } from "forms/SubscribeForm";
import styled from "styled-components";
import { Field as FormikField, useField } from "formik";
import { Colors } from "branding";

const FormInput = styled(Input)`
  border-bottom: 1px solid ${Colors.light_grey};
  font-size: 14px;
  color: ${Colors.black};

  &[data-com-onepassword-filled] {
    -webkit-box-shadow: 200px 200px 100px ${Colors.white} inset;
    box-shadow: 200px 200px 100px ${Colors.white} inset;
  }

  :focus {
    border-bottom-color: ${Colors.black};
  }

  &.has-error {
    border-bottom-color: ${Colors.error};
    color: ${Colors.error};
    ::placeholder {
      color: ${Colors.error};
    }
  }
`;

export default function ContactFormField({
  name,
  placeholder,
  type
}: {
  name: string;
  placeholder: string;
  type?: string;
}) {
  const [, { error, touched }] = useField(name);

  return (
    <FormInput
      name={name}
      className={touched && error ? "has-error" : ""}
      type={type}
      as={FormikField}
      placeholder={placeholder}
    />
  );
}
