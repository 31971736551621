import React from "react";
import { css } from "styled-components";
import { Visibility } from "../../../helpers/useVisibility";
import { useResponsive } from "../../../context/ResponsiveContext";
import { hexToRgb } from "../../../helpers/utils";
import { CANVAS_LEVEL } from "../../../helpers/zIndex";
import { CanvasComponent } from "./CanvasComponent";
import { Canvas } from "../../../types/Canvas";

export function CanvasDefault(props: {
  canvas: Canvas;
  isVisible: boolean;
  visibility: Visibility;
  proportion: number;
  imageUrl: string;
}) {
  const { isVisible, visibility, canvas, proportion, imageUrl } = props;

  const { align, components, animation, bg_opacity, parallax } = canvas;
  const bg_color = canvas.bg_color || "#ffffff";

  const { sizeCoefficient } = useResponsive();
  const color = hexToRgb(bg_color);

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: auto;
        background: rgba(${color.r}, ${color.g}, ${color.b}, 1);
      `}
    >
      <div
        css={css`
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: ${proportion * 100}%;
          background: url(${imageUrl});
          background-position: top center;
          background-size: cover;
          background-repeat: no-repeat;
          top: 0;
          will-change: opacity;
          opacity: ${bg_opacity};
        `}
      />
      <div
        css={css`
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: ${CANVAS_LEVEL};
        `}
      >
        {components.map((item, index) => {
          return (
            <CanvasComponent
              key={item.id}
              item={item}
              index={index}
              sizeCoefficient={sizeCoefficient}
              animation={isVisible ? animation : "none"}
              align={align}
              visibility={visibility}
              parallax={parallax}
            />
          );
        })}
      </div>
    </div>
  );
}
