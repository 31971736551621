import React, { CSSProperties } from "react";
import { Image } from "./Image";
import { Hr } from "./Hr";
import { Button } from "./Button";
import Router from "next/router";
import { Spacing, Column } from "../helpers/layout";
import { css } from "styled-components";
import { Colors } from "../branding";
import { MediumSubTitle } from "../typography";
import { CarouselElement } from "../types/Block";
import { useResponsive } from "../context/ResponsiveContext";
import { getLinkParams } from "./Link";

export function CarouselItem(props: {
  item: CarouselElement;
  height?: number;
  imgStyle?: CSSProperties;
}) {
  const { item, height, imgStyle } = props;
  const { isMobile } = useResponsive();

  return (
    <article
      css={css`
        position: relative;
        width: 100%;
      `}
    >
      <Image
        file={item.image ? item.image.value : null}
        style={imgStyle}
        height={height}
      />
      {item.name && item.description && (
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background: rgba(0, 0, 0, 0.59);
            color: ${Colors.white};
            padding: ${Spacing.m} 70px;
            box-sizing: border-box;

            ${isMobile &&
            css`
              padding-right: ${Spacing.m};
              padding-left: ${Spacing.m};
            `}
          `}
        >
          <Column gutter={isMobile ? Spacing.s : Spacing.m}>
            <MediumSubTitle
              css={css`
                letter-spacing: 0.2em;

                ${isMobile &&
                css`
                  font-size: 14px;
                `}
              `}
            >
              {item.name}
            </MediumSubTitle>
            <Hr size="large" color={Colors.white} align="center" />
            <div
              css={css`
                margin-bottom: ${isMobile ? Spacing.s : Spacing.l};
              `}
            >
              <p
                css={css`
                  margin: 0;
                  font-weight: 400;
                  font-size: 14px;

                  ${isMobile &&
                  css`
                    font-size: 12px;
                    line-height: 1.3;
                  `}
                `}
              >
                {item.description}
              </p>
            </div>
            {item.button_url && item.label && (
              <div>
                <Button
                  type="button"
                  style="white-border"
                  label={item.label}
                  onClick={() => {
                    if (item.button_url.match(`https?://`)) {
                      window.open(item.button_url, "_blank");
                    } else {
                      const { href, as } = getLinkParams(item.button_url);

                      Router.push(href, as);
                    }
                  }}
                />
              </div>
            )}
          </Column>
        </div>
      )}
    </article>
  );
}
