import React from "react";
import { TextBlock } from "../../types/Block";
import { EditorContent } from "../EditorContent";
import { css } from "styled-components";
import { Wrapper } from "../Wrapper";
import { useResponsive } from "../../context/ResponsiveContext";

export function TextBlockRenderer(props: { block: TextBlock }) {
  const { text, padding } = props.block.content;
  const { renderPadding } = useResponsive();

  return (
    <Wrapper>
      <div
        css={css`
          ${padding && renderPadding(padding)};
        `}
      >
        <EditorContent text={text} responsive />
      </div>
    </Wrapper>
  );
}
