import React, { useMemo } from "react";
import { NewsletterBlock, RelatedImage, Padding } from "types/Block";
import { Wrapper } from "components/Wrapper";
import { css } from "styled-components";
import { getImageUrl } from "helpers/getImageUrl";
import { Column, Spacing } from "helpers/layout";
import { fontFamilies } from "typography";
import { NewsletterSubscribeForm } from "forms/SubscribeForm";
import { Colors } from "branding";
import { useResponsive } from "context/ResponsiveContext";

export function FormWrapper(props: {
  image: RelatedImage;
  padding: Padding;
  position: NewsletterBlock["content"]["position"];
  title: string;
  text: string;
  form: JSX.Element;
}) {
  const { padding, image, position, title, text } = props;
  const { isMobile, renderPadding } = useResponsive();
  const bgCss = useMemo(() => {
    if (!image || !image?.value) return "";
    const imgUrl = getImageUrl(image.value, { width: "large" });
    return `background-image: ${`url(${imgUrl})`};`;
  }, [image]);

  return (
    <div
      css={css`
        ${bgCss}
        background-repeat: no-repeat;
        background-size: cover;
        height: auto;
        ${padding && renderPadding(padding)};
        display: flex;
        align-items: center;
      `}
    >
      <Wrapper>
        <Column
          align={
            position === "rightside"
              ? "flex-end"
              : position === "middle"
              ? "center"
              : "flex-start"
          }
        >
          <div
            css={css`
              background: ${Colors.white};
              max-width: 495px;
              height: auto;
              padding: ${isMobile ? Spacing.xl : Spacing.xxl};
              ${!bgCss ? "padding-inline: 0;" : ""}
              box-sizing: border-box;
              text-align: ${position === "middle" && "center"};
            `}
          >
            {title ? (
              <h3
                css={css`
                  font-family: ${fontFamilies.freight};
                  font-size: 30px;
                  text-transform: uppercase;
                `}
              >
                {title}
              </h3>
            ) : null}
            {text ? (
              <div
                css={css`
                  font-size: 16px;
                  line-height: 27px;
                  white-space: pre-line;
                  margin: ${position === "middle"
                    ? `${Spacing.xl} auto`
                    : `${Spacing.xl} 0`};
                `}
              >
                {text}
              </div>
            ) : null}
            {props.form}
          </div>
        </Column>
      </Wrapper>
    </div>
  );
}

export function NewsletterBlockRenderer(props: { block: NewsletterBlock }) {
  const {
    sendgrid_list_id,
    submit_label,
    success_message,
    redirect_url,
    success_action,
    ...wrapperProps
  } = props.block.content;

  return (
    <FormWrapper
      {...wrapperProps}
      form={
        <NewsletterSubscribeForm
          customListId={sendgrid_list_id}
          buttonLabel={submit_label}
          {...{ success_action, success_message, redirect_url }}
        />
      }
    />
  );
}
