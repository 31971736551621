import React, { useState, useRef } from "react";
import { AwardsBlock } from "../../types/Block";
import { css } from "styled-components";
import { Award } from "../../types/Award";
import { Column, Row, Spacing } from "../../helpers/layout";
import { Colors } from "../../branding";
import { Title } from "../../typography";
import groupBy from "lodash/groupBy";
import { Image } from "../Image";
import { Wrapper } from "../Wrapper";
import { Link } from "../Link";
import { PlainButton } from "../PlainButton";
import { VideoModal } from "../../modals/VideoModal";
import { Film } from "../../types/Film";
import { Rewarder } from "../../types/Rewarder";
import { PlainSelect } from "../../forms/SelectField";
import { useResponsive } from "../../context/ResponsiveContext";
import ArrowTiny from "../../static/images/arrowTiny.png";

export type Awards = {
  count: number;
  results: Award[];
};

function Separator() {
  const { isMobile } = useResponsive();

  return (
    <span
      css={css`
        padding: ${isMobile ? Spacing.s : Spacing.m};
      `}
    >
      |
    </span>
  );
}

function AwardSeries(props: { award: Award }) {
  const { award } = props;

  if (!award.products[0] || !award.products[0].serie) return null;

  return (
    <React.Fragment>
      <Link href={`/series/${award.products[0].serie.slug}`}>
        <span
          css={css`
            font-weight: 500;
          `}
        >
          {award.products[0].serie.name}
        </span>
      </Link>
      <Separator />
    </React.Fragment>
  );
}

function AwardProducts(props: { award: Award }) {
  const { award } = props;

  const productsSlice = award.products.slice(0, 3);
  const delta = award.products.length - productsSlice.length;

  if (!productsSlice.length) return null;

  return (
    <React.Fragment>
      <span
        css={css`
          font-weight: 200;
          font-style: italic;
        `}
      >
        {productsSlice.map((p, index) => (
          <React.Fragment key={`award-product-${p.slug}`}>
            <span>
              <Link href={`/shop/${p.slug}`}>{p.name}</Link>
              {index < productsSlice.length - 1 && <span>, </span>}
            </span>
          </React.Fragment>
        ))}
        {delta > 0 && (
          <span
            css={css`
              padding-left: ${Spacing.s};
            `}
          >
            + {delta} more
          </span>
        )}
      </span>
      <Separator />
    </React.Fragment>
  );
}

function AwardFilms(props: {
  award: Award;
  onFilmClick: (film: Film) => void;
}) {
  const { award } = props;

  if (!award.films.length) return null;

  return (
    <React.Fragment>
      <span>
        {award.films.map((f) => (
          <PlainButton
            key={`video-${f.id}`}
            css={css`
              font-weight: 200;
              font-style: italic;
            `}
            onClick={() => {
              props.onFilmClick(f);
            }}
          >
            {f.name}
          </PlainButton>
        ))}
      </span>
      <Separator />
    </React.Fragment>
  );
}

function RewarderAwards(props: {
  rewarder: Rewarder;
  awards: Award[];
  onFilmClick: (film: Film) => void;
}) {
  const { rewarder, awards } = props;
  const { isMobile } = useResponsive();

  return (
    <div
      css={css`
        padding-bottom: ${Spacing.m};
        font-size: 15px;
        line-height: 23px;
      `}
    >
      {rewarder && (
        <Row>
          <div
            css={css`
              flex: 0 0 ${isMobile ? "50px" : "100px"};
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: center;
              `}
            >
              <Image
                file={rewarder.logo}
                style={
                  isMobile
                    ? {
                        width: "100%",
                        height: "auto",
                      }
                    : {
                        height: "50px",
                        width: "auto",
                      }
                }
                height={50}
              />
            </div>
          </div>
          <div
            css={css`
              padding-top: 10px;
              margin-bottom: ${Spacing.l};
            `}
          >
            {rewarder.name}
          </div>
        </Row>
      )}
      <div
        css={
          isMobile
            ? css`
                padding-left: ${Spacing.xl};
              `
            : css`
                padding-left: calc(100px + ${Spacing.m});
              `
        }
      >
        <Column gutter={Spacing.m}>
          <div
            css={css`
              padding-left: ${isMobile ? Spacing.none : Spacing.l};

              ${isMobile &&
              css`
                font-size: 12px;
              `}
            `}
          >
            <Column gutter={Spacing.m}>
              {awards.map((award) => {
                return (
                  <div key={`award-${award.id}`}>
                    <AwardFilms award={award} onFilmClick={props.onFilmClick} />
                    <AwardProducts award={award} />
                    <AwardSeries award={award} />
                    <span
                      css={css`
                        font-weight: 500;
                      `}
                    >
                      {award.link ? (
                        <Link href={award.link}>{award.reward}</Link>
                      ) : (
                        award.reward
                      )}
                    </span>
                  </div>
                );
              })}
            </Column>
          </div>
        </Column>
      </div>
    </div>
  );
}

function AwardYearsDesktop(props: {
  years: string[];
  currentYear: string;
  onSelect: (year: string) => void;
}) {
  const { years } = props;
  const arrowPosition = years.findIndex((v) => v === props.currentYear);
  const arrowTop = arrowPosition !== -1 ? (arrowPosition + 1) * 28.5 : 0;

  return (
    <div>
      <Column
        align="flex-end"
        css={css`
          text-align: right;

          a {
            font-size: 15px;
            line-height: 20px;
          }
        `}
      >
        <PlainButton
          css={css`
            font-style: italic;
            text-decoration: underline;
          `}
          onClick={() => {
            props.onSelect("all");
          }}
        >
          All
        </PlainButton>

        {years.map((year) => (
          <div key={`sorted-${year}`}>
            <PlainButton
              onClick={() => {
                props.onSelect(year);
              }}
            >
              {year}
            </PlainButton>
          </div>
        ))}
      </Column>
      <div
        css={css`
          position: absolute;
          top: ${arrowTop + 3}px;
          left: 0;
          transition: top 300ms;
        `}
      >
        <img src={ArrowTiny} width="25px" alt="Arrow" />
      </div>
    </div>
  );
}

export function AwardsBlockRenderer(props: { block: AwardsBlock }) {
  const awardsData = props.block.content;
  const ref = useRef<HTMLDivElement>(null);
  const [filter, setFilter] = useState<string | "all">("all");
  const allYearsGroupedbyYear = groupBy(awardsData, "year");
  const allYearsSorted = Object.keys(allYearsGroupedbyYear).sort().reverse();
  const { isMobile } = useResponsive();

  const filteredYears = allYearsSorted.filter((year) => {
    if (filter === "all") return true;

    return year === filter;
  });

  const [currentVideo, setCurrentVideo] = useState<Film | null>(null);

  return (
    <div ref={ref}>
      <Wrapper>
        {isMobile && (
          <PlainSelect
            placeholder="Filter By Year"
            value={filter}
            fullBorder
            options={[
              {
                value: "all",
                label: "All",
              },
              ...allYearsSorted.map((year) => {
                return {
                  value: year,
                  label: year,
                };
              }),
            ]}
            onChange={(year) => {
              setFilter(year);

              if (ref.current) {
                window.scrollTo({
                  top: ref.current!.offsetTop - 50,
                  behavior: "smooth",
                });
              }
            }}
          />
        )}
        <Row
          gutter={Spacing.xxxl}
          css={css`
            padding: ${Spacing.xxl} 0;
          `}
        >
          <div
            css={css`
              flex: 1 0 30%;
            `}
          >
            <Column gutter={Spacing.none}>
              {filteredYears.map((year) => {
                const awards = allYearsGroupedbyYear[year];
                const groupedByRewarder = groupBy(awards, "rewarder.id");

                return (
                  <Row
                    key={`filtered-${year}`}
                    css={css`
                      margin-top: 0;
                    `}
                  >
                    <div
                      css={css`
                        position: sticky;
                        top: 120px;
                        flex: 0 0 15%;
                        height: 100px;

                        ${isMobile &&
                        css`
                          flex: 0 0 9px;
                        `}
                      `}
                    >
                      <div
                        css={css`
                          position: absolute;
                          top: 0;
                          left: 0;
                          right: 0;
                          margin: auto;

                          ${isMobile &&
                          css`
                            width: 50px;
                            padding: 20px 0;
                            background: ${Colors.white};
                          `}
                        `}
                      >
                        <Title
                          css={
                            isMobile &&
                            css`
                              font-size: 20px;
                            `
                          }
                        >
                          {year}
                        </Title>
                        <div
                          css={css`
                            position: absolute;
                            height: 15px;
                            width: 15px;
                            background-color: ${Colors.black};
                            border-radius: 50%;
                            display: inline-block;
                            position: absolute;
                            top: 16px;
                            right: -24px;

                            ${isMobile &&
                            css`
                              left: 0;
                              right: 0;
                              margin: auto;
                              top: 0;
                            `}
                          `}
                        ></div>
                      </div>
                    </div>
                    <div
                      css={css`
                        flex: 1 0 20%;
                        min-height: 100px;
                        padding-left: ${isMobile ? Spacing.xl : Spacing.xxl};
                        box-sizing: border-box;
                        padding-bottom: ${Spacing.xxl};
                        border-left: 2px solid ${Colors.black};
                      `}
                    >
                      <Column>
                        {Object.keys(groupedByRewarder).map((rewarderId) => {
                          const awards = groupedByRewarder[rewarderId];
                          const rewarder = awards[0].rewarder;

                          if (!rewarder) return null;

                          return (
                            <RewarderAwards
                              rewarder={rewarder}
                              awards={awards}
                              onFilmClick={(film) => {
                                setCurrentVideo(film);
                              }}
                              key={`rewarder-${rewarder.id}`}
                            />
                          );
                        })}
                      </Column>
                    </div>
                  </Row>
                );
              })}
            </Column>
          </div>
          {!isMobile && (
            <div
              css={css`
                position: sticky;
                top: 133px;
                flex: 0 0 auto;
                padding-left: 40px;
              `}
            >
              <AwardYearsDesktop
                currentYear={filter}
                years={allYearsSorted}
                onSelect={(year) => {
                  setFilter(year);

                  if (ref.current) {
                    window.scrollTo({
                      top: ref.current!.offsetTop - 50,
                      behavior: "smooth",
                    });
                  }
                }}
              />
            </div>
          )}
        </Row>
        <div
          css={css`
            width: 100%;
            height: 200px;
            background: white;
            position: sticky;
            bottom: 0;
            background: linear-gradient(
              to bottom,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 100%
            );
            pointer-events: none;
          `}
        ></div>
        {currentVideo && (
          <VideoModal
            muted
            video={{
              url: currentVideo.url,
              description: currentVideo.description,
            }}
            visible={true}
            onClose={() => {
              setCurrentVideo(null);
            }}
            button={{
              url: "/series",
              label: "Explore Series",
            }}
          />
        )}
      </Wrapper>
    </div>
  );
}
