import React from "react";
import { VideoTextBlock } from "../../types/Block";
import { css } from "styled-components";
import { Column, Spacing } from "../../helpers/layout";
import { Wrapper } from "../Wrapper";
import { Video } from "../Video";
import { EditorContent } from "../EditorContent";
import { useResponsive } from "../../context/ResponsiveContext";
import { ResponsiveLayout } from "../ResponsiveLayout";

export function VideoTextBlockRenderer(props: { block: VideoTextBlock }) {
  const {
    padding,
    layout,
    text,
    desktop_id,
    mobile_id,
    tablet_id,
    has_sound,
    bg_color,
    video_opacity,
    media_size,
  } = props.block.content;

  const { renderPadding, isMobile, isTablet } = useResponsive();

  const textWidth = layout === "fullsize" || isMobile ? "100%" : "30%";
  const mediaWidth = isMobile ? "100%" : isTablet ? "50%" : `${media_size}%`;

  const textContent = (
    <div
      css={css`
        flex: 1 0 auto;
        width: ${textWidth};

        ${isMobile &&
        css`
          text-align: center;
          padding: ${Spacing.l} 0;
          box-sizing: border-box;
        `}
      `}
    >
      <Column justify="center">
        <EditorContent text={text} responsive />
      </Column>
    </div>
  );

  const videoContent = (
    <div
      css={css`
        flex: 0 0 auto;
        width: ${mediaWidth};
      `}
    >
      <Video
        video={{
          desktop_id: desktop_id,
          tablet_id: tablet_id,
          mobile_id: mobile_id,
        }}
        hasSound={has_sound}
        opacity={video_opacity}
      />
    </div>
  );

  if (layout === "leftside" || layout === "rightside") {
    return (
      <ResponsiveLayout
        align="center"
        css={css`
          position: relative;
          width: 100%;
          box-sizing: border-box;
          background-color: ${bg_color};
          ${padding && renderPadding(padding)};
        `}
        gutter={isMobile ? Spacing.none : isTablet ? Spacing.l : Spacing.xxl}
      >
        {isMobile ? (
          <React.Fragment>
            {videoContent}
            {textContent}
          </React.Fragment>
        ) : layout === "leftside" ? (
          <React.Fragment>
            {videoContent}
            {textContent}
          </React.Fragment>
        ) : (
          layout === "rightside" && (
            <React.Fragment>
              {textContent}
              {videoContent}
            </React.Fragment>
          )
        )}
      </ResponsiveLayout>
    );
  }

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        box-sizing: border-box;
        background-color: ${bg_color};
        ${padding && renderPadding(padding)};
      `}
    >
      <div
        css={css`
          position: relative;
        `}
      >
        <Video
          video={{
            desktop_id: desktop_id,
            tablet_id: tablet_id,
            mobile_id: mobile_id,
          }}
          hasSound={has_sound}
          opacity={video_opacity}
        />
        <div
          css={css`
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
          `}
        >
          <Wrapper>{textContent}</Wrapper>
        </div>
      </div>
    </div>
  );
}
