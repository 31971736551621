import React from "react";
import { QuoteBlock } from "../../types/Block";
import { Wrapper } from "../Wrapper";
import { useResponsive } from "../../context/ResponsiveContext";
import { Column, Spacing } from "../../helpers/layout";
import { css } from "styled-components";
import { MediumSubTitle, ItalicTitle } from "../../typography";
import { Hr } from "../Hr";

export function QuoteBlockRenderer(props: { block: QuoteBlock }) {
  const content = props.block.content;
  const { isMobile } = useResponsive();

  return (
    <Wrapper>
      <Column justify="center" align="center">
        <div
          css={css`
            text-align: center;
            padding-top: ${Spacing.xxl};
            padding-bottom: ${Spacing.xxl};
          `}
        >
          <ItalicTitle
            css={css`
              font-size: ${isMobile ? "20px" : "30px"};
            `}
          >
            &ldquo;{content.quote}&rdquo;
          </ItalicTitle>
          <MediumSubTitle
            css={css`
              font-size: ${isMobile && "14px"};
            `}
          >
            <div
              css={css`
                padding-top: ${Spacing.l};
                padding-bottom: ${Spacing.l};
              `}
            >
              <Hr size="large" align="center" />
            </div>
            {content.author}
          </MediumSubTitle>
        </div>
      </Column>
    </Wrapper>
  );
}
