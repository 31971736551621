import throttle from "lodash/throttle";
import { useEffect, useState } from "react";

export type Visibility = {
  isVisible: boolean;
  viewedPercentage: number;
};

function checkVisibility(bounding: DOMRect, partial: boolean) {
  const { top, right, bottom, left, width, height } = bounding;

  if (top + right + bottom + left === 0) {
    return false;
  }

  const topCheck = partial ? top + height : top;
  const bottomCheck = partial ? bottom - height : bottom;
  const rightCheck = partial ? right - width : right;
  const leftCheck = partial ? left + width : left;

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  return (
    topCheck >= 0 &&
    leftCheck >= 0 &&
    bottomCheck <= windowHeight &&
    rightCheck <= windowWidth
  );
}

const throttleInterval = 100;

export const useVisibility = (
  el: React.RefObject<HTMLDivElement>,
  options?: {
    partial: boolean;
    scrollableEl: HTMLElement;
  }
): Visibility => {
  const [boundingRect, setBoundingRect] = useState<DOMRect | null>(null);
  const partial = options ? options.partial : true;

  useEffect(() => {
    const scrollableEl = options ? options.scrollableEl : window;

    const handleScrollOrResize = throttle(() => {
      if (!el.current) return;

      const bounding = el.current.getBoundingClientRect();

      setBoundingRect(bounding);
    }, throttleInterval);

    scrollableEl.addEventListener("scroll", handleScrollOrResize);
    window.addEventListener("resize", handleScrollOrResize);

    handleScrollOrResize();

    return () => {
      scrollableEl.removeEventListener("scroll", handleScrollOrResize);
      window.removeEventListener("resize", handleScrollOrResize);
    };
  }, [options]);

  if (!boundingRect) {
    return {
      isVisible: false,
      viewedPercentage: 0
    };
  }

  const ratio = (boundingRect.top / boundingRect.height) * -1;

  const viewedPercentage = ratio > 1 ? 1 : ratio < 0 ? 0 : ratio;

  return {
    isVisible: checkVisibility(boundingRect, partial),
    viewedPercentage: Number(viewedPercentage.toFixed(2))
  };
};
