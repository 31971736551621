import { ContactFormBlock } from "types/Block";
import ContactForm from "./ContactForm";
import { FormWrapper } from "../NewsletterBlockRenderer";

export default function ContactFormBlockRenderer(props: {
  block: ContactFormBlock;
}) {
  const { submit_label, success_action, redirect_url, ...wrapperProps } =
    props.block.content;
  if (!props.block.uuid) {
    return null;
  }

  return (
    <FormWrapper
      {...wrapperProps}
      form={
        <ContactForm
          submitLabel={submit_label || "Submit"}
          uuid={props.block.uuid}
          redirectUrl={success_action === "redirect" ? redirect_url : null}
        />
      }
    />
  );
}
