import React from "react";
import { Block } from "../types/Block";
import { ImageTextBlockRenderer } from "./blocks/ImageTextBlockRenderer";
import { QuoteBlockRenderer } from "./blocks/QuoteBlockRenderer";
import { CarouselBlockRenderer } from "./blocks/CarouselBlockRenderer";
import { VideoGridBlockRenderer } from "./blocks/VideoGridBlockRenderer";
import { AwardsBlockRenderer } from "./blocks/AwardsBlockRenderer";
import { TabsBlockRenderer } from "./blocks/TabsBlockRenderer";
import { CanvasBlockRenderer } from "./blocks/CanvasBlockRenderer";
import { TextBlockRenderer } from "./blocks/TextBlockRenderer";
import { PressBlockRenderer } from "./blocks/PressBlockRenderer";
import { NewsletterBlockRenderer } from "./blocks/NewsletterBlockRenderer";
import { ImageGridBlockRenderer } from "./blocks/ImageGridBlockRenderer";
import { ColumnsBlockRenderer } from "./blocks/ColumnsBlockRenderer";
import { InHomesBlockRenderer } from "./blocks/InHomesBlockRenderer";
import { ProductsBlockRenderer } from "./blocks/ProductsBlockRenderer";
import { FullscreenVideoBlockRenderer } from "./blocks/FullscreenVideoBlockRenderer";
import { VideoTextBlockRenderer } from "./blocks/VideoTextBlockRenderer";
import { HrBlockRenderer } from "./blocks/HrBlockRenderer";
import { SpacingBlockRenderer } from "./blocks/SpacingBlockRenderer";
import { ImageBlockRenderer } from "./blocks/ImageBlockRenderer";
import { SeriesBlockRenderer } from "./blocks/SeriesBlockRenderer";
import { EmbedVideoBlockRenderer } from "./blocks/EmbedVideoBlockRenderer";
import { DesignersBlockRenderer } from "./blocks/DesignersBlockRenderer";
import { LinksBlockRenderer } from "./blocks/LinksBlockRenderer";
import { useResponsive, Responsive } from "../context/ResponsiveContext";
import PressColumnsBlockRenderer from "./blocks/PressColumnsBlockRenderer";
import ContactFormBlockRenderer from "./blocks/ContactFormBlockRenderer";

function isVisible(responsive: Responsive, block: Block) {
  if (block.visibility === "none") {
    return false;
  }

  if (block.visibility === "mobile") {
    return responsive.isMobile;
  }

  if (block.visibility === "tablet") {
    return responsive.isTablet;
  }

  if (block.visibility === "desktop") {
    return responsive.isDesktop;
  }

  return true;
}

function getBlockComponent(block: Block) {
  switch (block.type) {
    case "image_text":
      return <ImageTextBlockRenderer block={block} />;
    case "video_text":
      return <VideoTextBlockRenderer block={block} />;
    case "text":
      return <TextBlockRenderer block={block} />;
    case "quote":
      return <QuoteBlockRenderer block={block} />;
    case "carousel":
      return <CarouselBlockRenderer block={block} />;
    case "video_grid":
      return <VideoGridBlockRenderer block={block} />;
    case "awards":
      return <AwardsBlockRenderer block={block} />;
    case "tabs":
      return <TabsBlockRenderer block={block} />;
    case "canvas":
      return <CanvasBlockRenderer block={block} />;
    case "press":
      return <PressBlockRenderer block={block} />;
    case "press_columns":
      return <PressColumnsBlockRenderer block={block} />;
    case "newsletter":
      return <NewsletterBlockRenderer block={block} />;
    case "contact_form":
      return <ContactFormBlockRenderer block={block} />;
    case "image_grid":
      return <ImageGridBlockRenderer block={block} />;
    case "columns":
      return <ColumnsBlockRenderer block={block} />;
    case "in_homes":
      return <InHomesBlockRenderer block={block} />;
    case "series":
      return <SeriesBlockRenderer block={block} />;
    case "products":
      return <ProductsBlockRenderer block={block} />;
    case "fullscreen_video":
      return <FullscreenVideoBlockRenderer block={block} />;
    case "hr":
      return <HrBlockRenderer block={block} />;
    case "spacing":
      return <SpacingBlockRenderer block={block} />;
    case "image":
      return <ImageBlockRenderer block={block} />;
    case "embedvideo":
      return <EmbedVideoBlockRenderer block={block} />;
    case "designer":
      return <DesignersBlockRenderer block={block} />;
    case "links":
      return <LinksBlockRenderer block={block} />;
  }
  return null;
}

export function BlocksRenderer(props: {
  blocks: Block[];
  afterFirstBlock?: React.ReactNode;
  hideRestBlocks?: boolean;
}) {
  const responsive = useResponsive();
  const blocks = props.blocks.filter((block) => isVisible(responsive, block));
  const firstBlock = blocks.length > 0 ? blocks[0] : null;
  const restBlocks = blocks.length > 0 ? blocks.slice(1) : [];

  return (
    <div>
      {firstBlock && (
        <section id={firstBlock?.uuid}>{getBlockComponent(firstBlock)}</section>
      )}
      {props.afterFirstBlock}
      {!props.hideRestBlocks &&
        restBlocks.map((block, index) => {
          return (
            <section id={block?.uuid} key={`block-${block.type}-${index}`}>
              {getBlockComponent(block)}
            </section>
          );
        })}
    </div>
  );
}
