import React from "react";
import { LinksBlock } from "../../types/Block";
import { Image } from "../Image";
import { css } from "styled-components";
import { Title, ItalicTitle } from "../../typography";
import { Row, Spacing } from "../../helpers/layout";
import { Colors } from "../../branding";
import { useWrapper } from "../../helpers/useWrapper";
import { useResponsive } from "../../context/ResponsiveContext";

export function LinksBlockRenderer(props: { block: LinksBlock }) {
  const { links } = props.block.content;
  const spacing = useWrapper();
  const { isMobile } = useResponsive();

  return (
    <div>
      {links.map((link, index) => {
        if (link.disabled) return null;

        return (
          <div
            key={`link--${index}`}
            css={css`
              padding: ${isMobile ? Spacing.m : Spacing.xxl} ${spacing};
              background: ${Colors.white};
              box-sizing: border-box;

              &:nth-child(odd) {
                background: ${Colors.very_light_grey};
              }

              a {
                text-decoration: none;
                color: inherit;
              }
            `}
          >
            <a href={link.link}>
              <div
                css={css`
                  width: 100%;
                  max-width: 680px;
                  margin: 0 auto;
                `}
              >
                <Row align="center" gutter={Spacing.xl}>
                  {link.image && (
                    <div
                      css={css`
                        flex: 0 0 30%;
                        text-align: center;
                      `}
                    >
                      <Image
                        style={{
                          width: "auto",
                          maxWidth: "100%",
                          maxHeight: isMobile ? 120 : 190,
                        }}
                        file={link.image.value}
                        width={isMobile ? 120 : 190}
                      />
                    </div>
                  )}
                  <div
                    css={css`
                      flex: 1 0 10%;
                    `}
                  >
                    <ItalicTitle
                      css={
                        isMobile &&
                        css`
                          font-size: 16px;
                        `
                      }
                    >
                      {link.label}
                    </ItalicTitle>
                    <Title
                      css={css`
                        line-height: 1;
                        font-weight: 400;
                      `}
                    >
                      {link.title}
                    </Title>
                  </div>
                </Row>
              </div>
            </a>
          </div>
        );
      })}
    </div>
  );
}
