import React from "react";
import { SpacingBlock } from "../../types/Block";
import { css } from "styled-components";
import { getSpacing } from "../../helpers/block";
import { useResponsive } from "../../context/ResponsiveContext";

export function SpacingBlockRenderer(props: { block: SpacingBlock }) {
  const { sizeCoefficient } = useResponsive();

  return (
    <div
      css={css`
        height: ${getSpacing(props.block.content.size, sizeCoefficient)};
      `}
    ></div>
  );
}
