import React from "react";
import { EmbedVideoBlock } from "../../types/Block";
import { VideoPlayer } from "../VideoPlayer";
import { css } from "styled-components";
import { Spacing, Column } from "../../helpers/layout";
import { Wrapper } from "../Wrapper";
import { useResponsive } from "../../context/ResponsiveContext";
import { ItalicTitle } from "../../typography";

export function EmbedVideoBlockRenderer(props: { block: EmbedVideoBlock }) {
  const { renderPadding, isMobile } = useResponsive();
  const video = props.block.content;

  return (
    <Wrapper>
      {video.url && (
        <div
          css={css`
            ${video.padding && renderPadding(video.padding)};
          `}
        >
          <VideoPlayer muted url={video.url} autoPlay />
          {(video.subtitle || video.title || video.description) && (
            <div
              css={css`
                padding: ${isMobile
                  ? `${Spacing.m} ${Spacing.none}`
                  : `${Spacing.xl} ${Spacing.xxl}`};
                max-width: 800px;
              `}
            >
              <Column justify="space-between">
                {video.subtitle && (
                  <div
                    css={css`
                      text-transform: uppercase;
                      font-size: ${isMobile ? "12px" : "16px"};
                      letter-spacing: 0.2em;
                    `}
                  >
                    {video.subtitle}
                  </div>
                )}
                {video.title && (
                  <div
                    css={css`
                      font-size: ${isMobile ? "16px" : "26px"};
                      letter-spacing: 0.1em;
                      text-transform: uppercase;
                      font-weight: 500;
                    `}
                  >
                    {video.title}
                    <span
                      css={css`
                        margin: 0 ${Spacing.m};
                        font-weight: 400;
                      `}
                    >
                      |
                    </span>
                    <span
                      css={css`
                        font-size: ${isMobile ? "12px" : "18px"};
                        font-weight: 400;
                      `}
                    >
                      ({video.duration})
                    </span>
                  </div>
                )}
                {video.description && (
                  <ItalicTitle
                    css={css`
                      font-size: ${isMobile ? "14px" : "20px"};
                    `}
                  >
                    {video.description}
                  </ItalicTitle>
                )}
              </Column>
            </div>
          )}
        </div>
      )}
    </Wrapper>
  );
}
