const PlayIcon = () => (
  <svg width="42px" viewBox="0 0 97 97">
    <path
      d="M50,5C25.2,5,5,25.1,5,50s20.2,45,45,45s45-20.1,45-45S74.8,5,50,5z M37.8,73.1V26.9L74.4,50L37.8,73.1z"
      fill="#ffffff"
      fillOpacity="1"
    />
  </svg>
);
export default PlayIcon;
