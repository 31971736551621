import { ProductThumb } from "./ProductThumb";
import { Spacing } from "../helpers/layout";
import { css } from "styled-components";
import { getProductSize } from "../helpers/product";
import { INCH_13, MOBILE } from "../branding";
import { Product } from "../types/Product";

export function ProductsGrid({
  products,
  columns = 2,
  onQuickShop,
}: {
  products: Product[];
  columns: number;
  onQuickShop: (product: Product) => void;
}) {
  return (
    <div
      css={css`
        display: grid;
        column-gap: calc(${Spacing.xl} * 2);

        grid-template-columns: repeat(1, 1fr);
        @media (min-width: ${MOBILE}px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: ${INCH_13}px) {
          grid-template-columns: repeat(${columns}, 1fr);
        }
      `}
    >
      {products.map((product) => {
        const productSize = getProductSize(product);

        return (
          <div
            key={product.id}
            css={css`
              min-width: 0;
              display: flex;
              justify-content: center;
              margin-bottom: ${Spacing.xxl};
              box-sizing: border-box;
              align-items: center;
            `}
          >
            <div
              css={css`
                max-width: ${productSize
                  ? `${(350 * productSize.width) / productSize.height}px`
                  : "auto"};

                width: 100%;

                img {
                  width: auto;
                  max-height: 300px;
                }
              `}
            >
              <ProductThumb
                key={product.id}
                product={product}
                enableOverlay
                onQuickShop={() => onQuickShop(product)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
