import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { CarouselBlock, CarouselElement } from "../../types/Block";
import { Wrapper } from "../Wrapper";
import { Row, Spacing, Column } from "../../helpers/layout";
import { EditorContent } from "../EditorContent";
import styled, { css } from "styled-components";
import { fontFamilies, ItalicTitle } from "../../typography";
import { Scrollbar } from "../Scrollbar";
import { CarouselItem } from "../CarouselItem";
import ArrowLeft from "../../icons/ArrowLeft";
import ArrowRight from "../../icons/ArrowRight";
import { Image } from "../Image";
import { Button } from "../Button";
import { Colors } from "../../branding";
import { useResponsive } from "../../context/ResponsiveContext";
import { ResponsiveLayout } from "../ResponsiveLayout";
import { getLinkParams } from "../Link";
import Router from "next/router";
import Slider from "react-slick";

const SliderButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  outline: none;
`;

function CarouselText(props: { text: any }) {
  return (
    <div
      css={css`
        p {
          margin-bottom: ${Spacing.l};
        }
        h2 {
          line-height: 1em;
        }

        padding-bottom: ${Spacing.l};
      `}
    >
      <EditorContent text={props.text} responsive />
    </div>
  );
}

function NestedCarousel(props: {
  items: CarouselElement[];
  layout: "leftside" | "rightside";
}) {
  const { items, layout } = props;
  const ref = useRef<any>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const responsive = useResponsive();
  const { isMobile } = responsive;

  const { getFontSize } = useResponsive();

  const goNext = () => {
    if (!ref.current) return;

    ref.current.slickNext();
  };

  const goPrev = () => {
    if (!ref.current) return;

    ref.current.slickPrev();
  };
  const slickGoTo = (slide: number) => {
    if (!ref.current) return;

    ref.current.slickGoTo(slide);
  };

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_oldIndex: number, newIndex: number) => {
      setCurrentIndex(newIndex);
    },
  };

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <Slider {...settings} ref={ref}>
        {items.map((item, idx) => {
          const imageContent = (
            <div
              css={css`
                display: flex;
                align-items: center;
                text-align: center;

                ${!isMobile &&
                css`
                  flex: 0 0 auto;
                  width: 60%;
                  height: 100%;
                `}

                ${isMobile &&
                css`
                  width: 100%;
                `}
              `}
            >
              <Image
                file={item.image ? item.image.value : null}
                height={500}
                style={{
                  width: "100%",
                }}
              />
            </div>
          );

          const textContent = (
            <div
              css={css`
                width: 20%;
                padding-bottom: 22px;

                ${!isMobile &&
                css`
                  flex: 1 0 20%;
                  padding-bottom: 50px;
                `}

                ${isMobile &&
                css`
                  width: 100%;
                  text-align: center;
                `}
              `}
            >
              <h2
                css={css`
                  font-family: ${fontFamilies.freight};
                  font-size: ${getFontSize(60)}px;
                  font-weight: 700;
                  line-height: 1em;
                `}
              >
                {item.name}
              </h2>
              {item.subtitle_1 && (
                <p
                  css={css`
                    font-weight: 300;
                    font-size: 16px;
                    text-transform: uppercase;
                    margin: ${Spacing.s} 0 ${Spacing.l} 0;
                  `}
                >
                  {item.subtitle_1}
                </p>
              )}
              {item.subtitle_2 && (
                <ItalicTitle
                  css={css`
                    font-size: 18px;
                  `}
                >
                  {item.subtitle_2}
                </ItalicTitle>
              )}
              <p
                css={css`
                  font-weight: 300;
                  font-size: ${getFontSize(14)}px;
                  margin: 0;
                  margin-top: ${Spacing.s};
                `}
              >
                {item.description}
              </p>
              {item.button_url && item.label && (
                <div
                  css={css`
                    margin-top: ${Spacing.l};
                  `}
                >
                  <Button
                    type="button"
                    label={item.label}
                    onClick={() => {
                      if (item.button_url.match(`https?://`)) {
                        window.open(item.button_url, "_blank");
                      } else {
                        const { href, as } = getLinkParams(item.button_url);

                        Router.push(href, as);
                      }
                    }}
                  />
                </div>
              )}
            </div>
          );
          return (
            <div
              css={css`
                opacity: ${currentIndex === idx ? 1 : 0};
                transition: opacity 300ms;
              `}
              key={idx}
            >
              <ResponsiveLayout
                gutter={isMobile ? Spacing.l : Spacing.xxl}
                align="center"
                css={css`
                  height: 100%;
                `}
              >
                {isMobile ? (
                  <React.Fragment>
                    {imageContent}
                    {textContent}
                  </React.Fragment>
                ) : layout === "leftside" ? (
                  <React.Fragment>
                    {textContent}
                    {imageContent}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {imageContent}
                    {textContent}
                  </React.Fragment>
                )}
              </ResponsiveLayout>
            </div>
          );
        })}
      </Slider>
      <div
        css={css`
          position: absolute;
          bottom: 0;
          width: 30%;
          right: ${layout === "rightside" ? 0 : "auto"};
          left: ${layout === "leftside" ? 0 : "auto"};
          display: flex;
          justify-content: center;

          ${isMobile &&
          css`
            width: 100%;
            left: 0;
            right: 0;
            margin: auto;
          `}
        `}
      >
        <Row align="center">
          <div>
            <SliderButton
              onClick={() => {
                goPrev();
              }}
            >
              <ArrowLeft />
            </SliderButton>
          </div>
          <div
            css={css`
              padding: 0 ${Spacing.m};
            `}
          >
            <Row align="center" gutter={Spacing.s}>
              {items.map((_item, index) => {
                return (
                  <div
                    key={`dot--${index}`}
                    css={css`
                      width: 11px;
                      height: 11px;
                      background: ${currentIndex === index
                        ? Colors.black
                        : Colors.light_grey};
                      border-radius: 50%;
                    `}
                    onClick={() => {
                      slickGoTo(index);
                    }}
                  ></div>
                );
              })}
            </Row>
          </div>
          <div>
            <SliderButton
              onClick={() => {
                goNext();
              }}
            >
              <ArrowRight />
            </SliderButton>
          </div>
        </Row>
      </div>
    </div>
  );
}

function ScrollerCarousel(props: { items: CarouselElement[] }) {
  const { isMobile } = useResponsive();

  return (
    <Scrollbar autoHeight>
      <Row gutter={isMobile ? Spacing.l : Spacing.xxl}>
        {props.items.map((item, idx) => {
          const height = isMobile ? 200 : 307;

          return (
            <div
              css={css`
                flex: 0 0 auto;
              `}
              key={idx}
            >
              <CarouselItem
                item={item}
                height={height}
                imgStyle={{ height: height, maxWidth: "none", width: "auto" }}
              />
            </div>
          );
        })}
      </Row>
    </Scrollbar>
  );
}

function SliderCarousel(props: { items: CarouselElement[] }) {
  const { items } = props;
  const [blockWidth, setBlockWidth] = useState<any>(1);
  const [size, setSize] = useState(0);
  const ref = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const goNext = () => {
    if (!ref.current) return;

    ref.current.slickNext();
  };

  const goPrev = () => {
    if (!ref.current) return;

    ref.current.slickPrev();
  };

  useEffect(() => {
    setBlockWidth(containerRef.current?.clientWidth || blockWidth);
  }, [blockWidth, containerRef, size]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const buttonStyles = css`
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    bottom: 0;
    margin: auto;
    background: rgba(255, 255, 255, 0.5);
    appearance: none;
    border: none;
  `;

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div
      css={css`
        position: relative;
      `}
      ref={containerRef}
    >
      <Slider {...settings} ref={ref}>
        {items.map((item, idx) => (
          <div key={`carousel-slide--${idx}`}>
            <div
              css={css`
                display: flex;
                width: 100%;
                height: 100%;
                align-items: center;
              `}
            >
              <CarouselItem
                key={idx}
                item={item}
                imgStyle={{
                  width: "auto",
                  height: Math.round(
                    (blockWidth / (item.image?.value.width || 600)) *
                      (item.image?.value.height || 400)
                  ),
                  maxHeight: 800,
                  maxWidth: "100%",
                  margin: "0 auto",
                }}
              />
            </div>
          </div>
        ))}
      </Slider>
      <SliderButton
        css={css`
          ${buttonStyles};
          left: 0;
        `}
        onClick={() => {
          goPrev();
        }}
      >
        <ArrowLeft />
      </SliderButton>
      <SliderButton
        css={css`
          ${buttonStyles};
          right: 0;
        `}
        onClick={() => {
          goNext();
        }}
      >
        <ArrowRight />
      </SliderButton>
    </div>
  );
}

function SimpleCarousel(props: { block: CarouselBlock }) {
  const { isMobile } = useResponsive();
  const Layout = isMobile ? Column : Row;
  const { text, layout, style, items, show_text } = props.block.content;

  const itemsContent = (
    <div
      css={
        !isMobile &&
        css`
          flex: 1 0 20%;
          width: 20%;
        `
      }
    >
      {style === "scroller" ? (
        <ScrollerCarousel items={items} />
      ) : style === "slider" ? (
        <SliderCarousel items={items} />
      ) : null}
    </div>
  );

  const textContent = show_text ? (
    <div
      css={
        isMobile
          ? css`
              text-align: center;
            `
          : css`
              flex: 0 0 30%;
              width: 30%;
            `
      }
    >
      <CarouselText text={text} />
    </div>
  ) : null;

  return (
    <Layout gutter={isMobile ? Spacing.l : Spacing.xxl} align="center">
      {layout === "leftside" ? (
        <React.Fragment>
          {textContent}
          {itemsContent}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {itemsContent}
          {textContent}
        </React.Fragment>
      )}
    </Layout>
  );
}

export function CarouselBlockRenderer(props: { block: CarouselBlock }) {
  const { style, items, layout } = props.block.content;

  return (
    <Wrapper>
      <div
        css={css`
          margin: 50px 0;
        `}
      >
        {style === "nested-slider" ? (
          <NestedCarousel items={items} layout={layout} />
        ) : (
          <SimpleCarousel block={props.block} />
        )}
      </div>
    </Wrapper>
  );
}
