import React, { useState } from "react";
import { ImageGridBlock } from "../../types/Block";
import { Row, Spacing } from "../../helpers/layout";
import { getImageUrl } from "../../helpers/getImageUrl";
import { Wrapper } from "../Wrapper";
import { css } from "styled-components";
import { Press } from "../../types/Press";
import { Image } from "../Image";
import Carousel, { Modal, ModalGateway } from "react-images";
import { MasonryGrid } from "../MasonryGrid";
import { useResponsive } from "../../context/ResponsiveContext";

export type PressResult = {
  count: number;
  results: Array<Press>;
};

export function ImageGridBlockRenderer(props: { block: ImageGridBlock }) {
  const { layout, images, padding } = props.block.content;
  const [image, setImage] = useState<number | null>(null);
  const { renderPadding, isTablet, isMobile, is13inch } = useResponsive();

  return (
    <div
      css={css`
        ${padding && renderPadding(padding)};
      `}
    >
      <Wrapper>
        {layout === "logos" && (
          <Row
            gutter={Spacing.xl}
            align="center"
            justify="center"
            wrap="wrap"
            width="100%"
          >
            {images.map((item, index) => {
              return (
                <div
                  key={`image--${index}`}
                  css={css`
                    flex: 0 0 auto;
                    margin-bottom: ${Spacing.xl};
                  `}
                >
                  <Image
                    file={item.image ? item.image.value : null}
                    height={120}
                    style={{
                      maxWidth: "200px",
                      maxHeight: "100px",
                      width: "auto",
                      height: "auto",
                    }}
                  />
                </div>
              );
            })}
          </Row>
        )}
        {layout === "columns" && (
          <Row gutter={Spacing.xl}>
            {images.map((item, index) => {
              return (
                <div
                  key={`image--${index}`}
                  css={css`
                    flex: ${`1 0 10%`};
                    cursor: pointer;
                    margin-bottom: ${isTablet && Spacing.xl};
                  `}
                  onClick={() => {
                    setImage(index);
                  }}
                >
                  <Image
                    file={item.image ? item.image.value : null}
                    width={isMobile ? 300 : 500}
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
              );
            })}
          </Row>
        )}
        {layout === "masonry" && (
          <MasonryGrid columns={is13inch ? 2 : 3} items={images}>
            {(item, index) => {
              if (!item.image) return null;

              return (
                <div
                  onClick={() => {
                    setImage(index);
                  }}
                  css={css`
                    cursor: pointer;
                    margin-bottom: 2%;
                  `}
                  className="grid-item"
                  key={`masonry-item--${index}`}
                >
                  <div
                    css={css`
                      position: relative;
                      width: 100%;
                      height: 0;
                      padding-bottom: ${(item.image.value.height /
                        item.image.value.width) *
                      100}%;
                    `}
                  >
                    <Image
                      file={item.image.value}
                      width={isMobile ? 300 : 500}
                      style={{
                        position: "absolute",
                        width: "100%",
                        top: 0,
                        left: 0,
                      }}
                    />
                  </div>
                </div>
              );
            }}
          </MasonryGrid>
        )}
        <ModalGateway>
          {image !== null ? (
            <Modal
              onClose={() => {
                setImage(null);
              }}
            >
              <Carousel
                currentIndex={image}
                views={images.map((item) => {
                  return {
                    src: getImageUrl(item.image ? item.image.value : null, {
                      width: "large",
                    }),
                  };
                })}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </Wrapper>
    </div>
  );
}
