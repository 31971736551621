import React, { useState } from "react";
import { PressBlock } from "../../types/Block";
import { Row, Spacing, Column } from "../../helpers/layout";
import { Scrollbar } from "../Scrollbar";
import { Wrapper } from "../Wrapper";
import { css } from "styled-components";
import { Press } from "../../types/Press";
import { PressItem } from "../PressItem";
import Carousel, { Modal, ModalGateway } from "react-images";
import { getImageUrl } from "../../helpers/getImageUrl";
import { getFileUrl } from "../../helpers/getFileUrl";
import { useResponsive } from "../../context/ResponsiveContext";

export type PressResult = {
  count: number;
  results: Array<Press>;
};

type ModalImage = {
  src: string;
};

export function PressBlockRenderer(props: { block: PressBlock }) {
  const [modalIsOpen, setModalOpen] = useState(false);
  const [images, setImages] = useState<ModalImage[]>([]);
  const pressData = props.block.content;
  const { isMobile } = useResponsive();

  return (
    <Wrapper>
      <Column>
        <div
          css={css`
            padding: ${Spacing.xxl} 0;
            text-align: center;
          `}
        >
          <Scrollbar autoHeight>
            <Row gutter={isMobile ? Spacing.l : Spacing.xxl} align="center">
              {pressData.map((item: Press) => {
                switch (item.type) {
                  case "LINK": {
                    return (
                      <a key={item.id} href={`${item.link}`} target="_blank">
                        <PressItem item={item} />
                      </a>
                    );
                  }

                  case "FILE": {
                    return (
                      <a
                        key={item.id}
                        href={getFileUrl(item.file)}
                        target="_blank"
                      >
                        <PressItem item={item} />
                      </a>
                    );
                  }

                  case "IMAGE": {
                    const images = [
                      {
                        src: getImageUrl(item.image, {
                          width: "large",
                        }),
                      },
                    ];

                    return (
                      <div
                        key={item.id}
                        onClick={() => {
                          setModalOpen(true);
                          setImages(images);
                        }}
                        css={css`
                          cursor: pointer;
                        `}
                      >
                        <PressItem item={item} />
                      </div>
                    );
                  }
                }
              })}
              <ModalGateway>
                {modalIsOpen ? (
                  <Modal onClose={() => setModalOpen(false)}>
                    <Carousel views={images} />
                  </Modal>
                ) : null}
              </ModalGateway>
            </Row>
          </Scrollbar>
        </div>
      </Column>
    </Wrapper>
  );
}
