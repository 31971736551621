import * as Yup from "yup";

export type ContactFormValues = {
  first_name: string;
  last_name: string;
  email: string;
  message: string;
};

export const contactFormSchema = Yup.object().shape({
  email: Yup.string().required("Required").email("Enter a valid email"),
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  message: Yup.string().required("Required")
});

export const initialValues: ContactFormValues = {
  first_name: "",
  last_name: "",
  email: "",
  message: ""
};
