import React from "react";
import { ImageBlock } from "../../types/Block";
import { css } from "styled-components";
import { Image } from "../Image";
import { useResponsive } from "../../context/ResponsiveContext";

export function ImageBlockRenderer(props: { block: ImageBlock }) {
  const { image, padding, size } = props.block.content;
  const { renderPadding } = useResponsive();

  if (!image) return null;

  return (
    <div
      css={css`
        ${padding && renderPadding(padding)};
        text-align: center;
        box-sizing: border-box;
      `}
    >
      <div
        css={css`
          display: inline-block;
          width: ${size || "100"}%;
        `}
      >
        <div
          css={css`
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: ${(image.value.height / image.value.width) * 100}%;
          `}
        >
          <Image
            file={image.value}
            width={"large"}
            style={{
              position: "absolute",
              width: "100%",
              top: 0,
              left: 0,
            }}
          />
        </div>
      </div>
    </div>
  );
}
