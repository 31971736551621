import React from "react";
import * as types from "../../../types/Canvas";
import { css } from "styled-components";
import { EditorContent } from "../../EditorContent";
import { Visibility } from "../../../helpers/useVisibility";

function calculatePosition(align: types.Align) {
  if (align === "top-left") {
    return css`
      top: 0px;
      left: 0px;
    `;
  }
  if (align === "top-center") {
    return css`
      top: 0px;
      left: 0px;
      right: 0px;
      margin: auto;
    `;
  }
  if (align === "top-right") {
    return css`
      top: 0px;
      right: 0px;
    `;
  }
  if (align === "left-center") {
    return css`
      top: 0px;
      left: 0px;
      bottom: 0px;
      margin: auto;
    `;
  }
  if (align === "right-center") {
    return css`
      top: 0px;
      right: 0px;
      bottom: 0px;
      margin: auto;
    `;
  }
  if (align === "bottom-right") {
    return css`
      bottom: 0px;
      right: 0px;
    `;
  }
  if (align === "bottom-center") {
    return css`
      bottom: 0px;
      left: 0px;
      right: 0px;
      margin: auto;
    `;
  }
  if (align === "bottom-left") {
    return css`
      bottom: 0px;
      left: 0px;
    `;
  }
  if (align === "center-center") {
    return css`
      left: 0px;
      right: 0px;
      top: 0;
      bottom: 0;
      margin: auto;
    `;
  }

  return null;
}

export function CanvasComponent(props: {
  item: types.CanvasComponent;
  sizeCoefficient: number;
  index: number;
  animation: types.Animation | "none";
  align: types.Align;
  parallax: types.Parallax;
  visibility: Visibility;
}) {
  const {
    item,
    sizeCoefficient,
    animation,
    align,
    parallax,
    index,
    visibility
  } = props;
  const x = item.position.x * sizeCoefficient;
  const y = item.position.y * sizeCoefficient;
  let width = item.size.width * sizeCoefficient;
  const height = item.size.height * sizeCoefficient;

  width =
    typeof window !== "undefined" && width > window.innerWidth
      ? window.innerWidth
      : width;

  return (
    <div
      css={css`
        position: absolute;
        ${calculatePosition(align)};
        transform: translate(${x}px, ${y}px);
        width: ${width}px;
        height: ${height}px;

        p {
          margin-top: 0;
        }
      `}
    >
      <div
        className={`canvas-component`}
        css={
          parallax !== "text" &&
          animation !== "none" &&
          css`
            animation-name: ${animation};
            animation-delay: ${index * 200}ms;
            animation-duration: 1.5s;
            animation-fill-mode: both;
          `
        }
        style={
          parallax === "text"
            ? {
                opacity: visibility.viewedPercentage * 5
              }
            : undefined
        }
      >
        <EditorContent text={item.text} responsive disableMinimalFont />
      </div>
    </div>
  );
}
