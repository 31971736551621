import { NextPage } from "next";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { getCommonData } from "../helpers/common-data";
import { getAnnouncements } from "../helpers/getAnnouncements";
import { SubTitle, Title } from "../typography";
import { Wrapper } from "../components/Wrapper";
import { CommonData } from "../types/CommonData";
import { Announcement } from "../types/Announcement";

import { Hr } from "../components/Hr";
import { css } from "styled-components";
import { Spacing, Column } from "../helpers/layout";

export const Error: NextPage<{
  statusCode?: number;
  commonData: CommonData;
  announcements: Announcement[];
}> = ({ statusCode, announcements }) => {
  return (
    <>
      <Header announcements={announcements} />
      <div
        css={css`
          background-image: url("/static/images/404.jpg");
          background-repeat: no-repeat;
          width: 100%;
          background-size: cover;
          padding: ${Spacing.xxxl} 0;
          box-sizing: border-box;
        `}
      >
        <Wrapper>
          <div
            css={css`
              text-align: center;
              max-width: 600px;
              margin: 0 auto;
            `}
          >
            <Column justify="center" gutter={Spacing.l}>
              <Title>Something went wrong</Title>
              {statusCode === 404 && (
                <>
                  <SubTitle>THIS PAGE IS MISSING</SubTitle>
                  <Hr size="medium" align="center" />
                  <div
                    css={css`
                      font-size: 16px;
                    `}
                  >
                    Unfortunately the content you’re looking for isn’t here.
                    There may be a misspelling in your web address or you may
                    have clicked a link for content that no longer exists.
                    Please use the menus to find what you are looking for.
                  </div>
                </>
              )}
            </Column>
          </div>
        </Wrapper>
      </div>

      <Footer />
    </>
  );
};

Error.getInitialProps = async ({ res, req, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;

  const commonData = await getCommonData(req);
  const announcements = await getAnnouncements("ALL");

  return {
    statusCode,
    commonData: commonData,
    announcements,
  };
};

export default Error;
