import React, { useEffect, useState } from "react";
import { VideoGridBlock } from "../../types/Block";
import { css } from "styled-components";
import { Colors } from "../../branding";
import { Column, Row, Spacing } from "../../helpers/layout";
import { getImageUrl } from "../../helpers/getImageUrl";
import { Image } from "../../components/Image";
import { VideoModal } from "../../modals/VideoModal";
import { Film } from "../../types/Film";
import PlayIcon from "../../icons/PlayIcon";
import LocationIcon from "../../icons/LocationIcon";
import Router, { useRouter } from "next/router";
import { fontFamilies } from "../../typography";
import { Wrapper } from "../Wrapper";
import { useResponsive } from "../../context/ResponsiveContext";

export function VideoGridItem(props: { video: Film; onClick: () => void }) {
  const { video } = props;
  const truncated = video.description.substring(0, 200);
  const description =
    truncated.length < video.description.length ? truncated + "..." : truncated;
  const { isTablet, isMobile, is13inch } = useResponsive();

  return (
    <div
      css={css`
        position: relative;
        height: 400px;
        box-sizing: border-box;
        padding: ${Spacing.l};
        margin-bottom: ${Spacing.xl};
        cursor: pointer;
        overflow: hidden;

        ${(isTablet || isMobile) &&
        css`
          height: 200px;
        `}

        &:hover {
          .bg {
            opacity: 1;
          }
          .gradient {
            top: 0;
          }
          .description {
            max-height: 140px;
            opacity: 1;
          }
        }
      `}
      onClick={() => {
        props.onClick();
      }}
    >
      <div
        className="bg"
        css={css`
          position: absolute;
          background-image: ${video.cover
            ? `url(${getImageUrl(video.cover, {
                width: 600,
              })})`
            : null};
          background-repeat: no-repeat;
          background-size: cover;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.5;
          transition: opacity 500ms;
        `}
      ></div>
      <Column justify="space-between">
        <Row wrap="wrap">
          {video.awards.map((award) => {
            if (award.rewarder) {
              return (
                <div
                  key={`award-rewarde-${award.id}`}
                  onClick={() => {
                    Router.push("/about");
                  }}
                  css={css`
                    position: relative;
                    opacity: 1;
                    transition: opacity;
                    transition-duration: 0.3s;

                    :hover {
                      opacity: 0.7;
                    }
                  `}
                >
                  <Image
                    file={
                      video.white_logos
                        ? award.rewarder.white_logo
                        : award.rewarder.logo
                    }
                    style={{
                      width: "auto",
                      maxWidth: "none",
                      height: `${
                        isTablet || isMobile
                          ? "30px"
                          : is13inch
                          ? "90px"
                          : "110px"
                      }`,
                    }}
                    height={isTablet || isMobile ? 30 : 110}
                  />
                </div>
              );
            }

            return null;
          })}
        </Row>

        <div
          css={css`
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            padding: ${Spacing.l};
            padding-top: ${Spacing.xl};
            box-sizing: border-box;
          `}
        >
          <div
            className="gradient"
            css={css`
              position: absolute;
              top: 100%;
              left: 0;
              width: 100%;
              height: 100%;
              transition: all 400ms;
              background: linear-gradient(
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 1) 100%
              );
              content: "";
            `}
          ></div>
          <div
            css={css`
              position: relative;
            `}
          >
            <Row justify="space-between">
              <div
                css={css`
                  flex: 1 0 30%;
                `}
              >
                <Row>
                  <div>
                    <PlayIcon />
                  </div>
                  <div>
                    <Row justify="space-between" align="flex-end">
                      <div
                        css={css`
                          font-family: ${fontFamilies.gotham};
                          font-weight: 400;
                        `}
                      >
                        <h2
                          css={css`
                            margin: 0;
                            font-size: 26px;
                            line-height: 29px;
                            text-transform: uppercase;
                            letter-spacing: 0.15em;

                            ${(isTablet || isMobile) &&
                            css`
                              font-size: 16px;
                              line-height: 19px;
                            `}
                          `}
                        >
                          {video.name}
                        </h2>
                        <h3
                          css={css`
                            margin: 0;
                            font-size: 16px;
                            line-height: 27px;
                            text-transform: uppercase;
                            font-weight: 400;

                            ${(isTablet || isMobile) &&
                            css`
                              font-size: 10px;
                              line-height: 17px;
                            `}
                          `}
                        >
                          {video.subtitle} ({video.duration})
                        </h3>
                      </div>
                      {video.location && !isMobile && (
                        <div
                          css={css`
                            position: relative;
                            flex: 0 0 auto;
                            padding-left: 30px;
                          `}
                        >
                          <div
                            css={css`
                              position: absolute;
                              top: 0;
                              left: 0;
                            `}
                          >
                            <LocationIcon />
                          </div>
                          <div
                            css={css`
                              max-width: 130px;
                            `}
                          >
                            <h3
                              css={css`
                                margin-top: 0;
                                font-family: ${fontFamilies.gotham};
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 19px;
                                text-transform: uppercase;
                                letter-spacing: 0.05em;

                                ${isTablet &&
                                css`
                                  font-size: 10px;
                                `}
                              `}
                            >
                              {video.location}
                            </h3>
                          </div>
                        </div>
                      )}
                    </Row>

                    {!(isTablet || isMobile) && description && (
                      <div
                        className="description"
                        css={css`
                          max-height: 0;
                          overflow: hidden;
                          transition: all 500ms;
                          opacity: 0;
                          margin-top: ${Spacing.l};
                          font-family: ${fontFamilies.gotham};
                          font-size: 14px;
                          line-height: 23px;
                        `}
                      >
                        {description}
                      </div>
                    )}
                  </div>
                </Row>
              </div>
            </Row>
          </div>
        </div>
      </Column>
    </div>
  );
}

export function VideoGridBlockRenderer(props: { block: VideoGridBlock }) {
  const { asPath, push, pathname } = useRouter();
  const [currentVideo, setCurrentVideo] = useState<Film | null>(null);
  const { isMobile } = useResponsive();

  const deleteVideoId = () => {
    const search = asPath.split("?")[1];
    const queryParams = new URLSearchParams(search);
    setCurrentVideo(null);

    if (queryParams.has("videoId")) {
      queryParams.delete("videoId");
      const hasParams = queryParams.toString().length > 0;
      const url = `${asPath.split("?")[0]}${
        hasParams ? "?" + queryParams.toString() : ""
      }`;
      push(pathname, url, {
        shallow: true,
      });
    }
  };

  const updateVideoId = (videoId: string) => {
    const search = asPath.split("?")[1];
    const queryParams = new URLSearchParams(search);
    let url = "";
    if (queryParams.has("videoId")) {
      queryParams.set("videoId", videoId);
      url = `${asPath.split("?")[0]}?${queryParams.toString()}`;
    } else {
      url = `${asPath}?videoId=${videoId}`;
    }

    push(pathname, url, {
      shallow: true,
    });
  };

  useEffect(() => {
    const search = asPath.split("?")[1];
    const queryParams = new URLSearchParams(search);
    if (queryParams.has("videoId")) {
      const videoId = queryParams.get("videoId");
      const video = props.block.content.find(
        (video) => video.id.toString() === videoId
      );

      if (video) {
        setCurrentVideo(video);
      }
    }
  }, [asPath, props.block.content]);

  return (
    <React.Fragment>
      <div
        css={css`
          background-color: ${Colors.black};
          color: white;
          padding: ${Spacing.xl} 0;
        `}
      >
        <Wrapper>
          <Row
            css={css`
              width: 100%;
            `}
            wrap="wrap"
            gutter={Spacing.xl}
          >
            {props.block.content.map((video) => {
              return (
                <div
                  css={css`
                    flex: ${isMobile ? `100%` : `0 1 calc(50% - ${Spacing.m})`};
                    margin-right: ${isMobile && 0};
                    &:nth-child(2n) {
                      margin-right: 0;
                    }
                  `}
                  key={`video-${video.id}`}
                >
                  <VideoGridItem
                    video={video}
                    onClick={() => {
                      updateVideoId(video.id.toString());
                    }}
                  />
                </div>
              );
            })}
          </Row>
        </Wrapper>
      </div>
      {currentVideo && (
        <VideoModal
          video={{
            url: currentVideo.url,
            description: currentVideo.description,
          }}
          visible={true}
          onClose={() => {
            deleteVideoId();
          }}
          muted={false}
          button={{
            url: currentVideo.button_url,
            label: currentVideo.button_label,
          }}
        />
      )}
    </React.Fragment>
  );
}
