import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import { css } from "styled-components";
import { Colors } from "../branding";
import { Spacing } from "../helpers/layout";
import { useVisibility } from "../helpers/useVisibility";
import SoundOff from "../icons/SoundOff";
import SoundOn from "../icons/SoundOn";
import { PlainButton } from "./PlainButton";

export function VideoPlayer(props: { url: string; autoPlay?: boolean; muted: boolean; }) {
  const [volume, setVolume] = useState(props.muted ? 0 : 1);
  const [paused, setPaused] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const visibility = useVisibility(ref);
  const shouldPlay = props.autoPlay && visibility.isVisible && !paused;

  return (
    <div
      css={css`
        position: relative;
        padding-top: 56.25%;
        width: 100%;
      `}
      ref={ref}
    >
      <PlainButton
        onClick={() => {
          setVolume(volume === 0 ? 1 : 0);
        }}
        className="sound-button"
        css={css`
          z-index: 1;
          position: absolute;
          bottom: 3rem;
          right: ${Spacing.xl};

          svg {
            fill: ${Colors.white};
          }
        `}
      >
        {volume === 0 ? <SoundOff /> : <SoundOn />}
      </PlainButton>
      <ReactPlayer
        url={props.url}
        playing={shouldPlay}
        controls
        muted={!!props.muted}
        volume={volume}
        width="100%"
        height="100%"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
        onPause={() => {
          setPaused(true);
        }}
      />
    </div>
  );
}
