import styled, { css } from "styled-components";
import { Row, Spacing, Column } from "../../helpers/layout";
import { Wrapper } from "../Wrapper";
import { getImageUrl } from "../../helpers/getImageUrl";
import { getFileUrl } from "../../helpers/getFileUrl";
import { useResponsive } from "../../context/ResponsiveContext";
import { PressColumnsBlock } from "../../types/Block";
import Carousel, { Modal, ModalGateway } from "react-images";
import { useState } from "react";
import { PressColumnsItem } from "../PressColumnsItem";
import { Press } from "../../types/Press";
import { SMALL_TABLET, TABLET } from "../../branding";

type ModalImage = {
  src: string;
};

const StyledItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 380px;

  @media (min-width: ${SMALL_TABLET}px) {
    width: calc(100% / 3 - ${Spacing.l});
  }
`;

const PressColumnsBlockRenderer = ({ block }: { block: PressColumnsBlock }) => {
  const [modalIsOpen, setModalOpen] = useState(false);
  const [images, setImages] = useState<ModalImage[]>([]);
  const { isMobile } = useResponsive();
  const pressData = block.content.press_columns;

  return (
    <Wrapper>
      <Column>
        <div
          css={css`
            padding: ${Spacing.xl} 0;
            text-align: center;
          `}
        >
          <Row
            wrap="wrap"
            gutter={isMobile ? Spacing.none : Spacing.l}
            align="stretch"
            css={css`
              max-width: ${TABLET}px;
              margin: 0 auto;
            `}
          >
            {pressData.map((item: Press) => {
              switch (item.type) {
                case "LINK": {
                  return (
                    <StyledItem key={item.id}>
                      <PressColumnsItem
                        href={item.link as string}
                        item={item}
                      />
                    </StyledItem>
                  );
                }

                case "FILE": {
                  return (
                    <StyledItem key={item.id}>
                      <PressColumnsItem
                        href={getFileUrl(item.file)}
                        item={item}
                      />
                    </StyledItem>
                  );
                }

                case "IMAGE": {
                  const images = [
                    {
                      src: getImageUrl(item.image, {
                        width: "large",
                      }),
                    },
                  ];

                  return (
                    <StyledItem key={item.id}>
                      <div key={item.id}>
                        <PressColumnsItem
                          onClick={() => {
                            setModalOpen(true);
                            setImages(images);
                          }}
                          item={item}
                        />
                      </div>
                    </StyledItem>
                  );
                }
              }
            })}
            <ModalGateway>
              {modalIsOpen ? (
                <Modal onClose={() => setModalOpen(false)}>
                  <Carousel views={images} />
                </Modal>
              ) : null}
            </ModalGateway>
          </Row>
        </div>
      </Column>
    </Wrapper>
  );
};

export default PressColumnsBlockRenderer;
