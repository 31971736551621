import { Image } from "./Image";
import { css } from "styled-components";
import { Colors } from "../branding";
import { Press } from "../types/Press";
import { Button, LinkButton } from "./Button";

export function PressColumnsItem(props: {
  item: Press;
  onClick?: () => void;
  href?: string;
}) {
  const { item, href } = props;
  const onClick = props.onClick;

  return (
    <div
      css={css`
        flex: 1;
        width: 100%;
        position: relative;
        text-align: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:hover {
          .overlay {
            opacity: 1;
          }
        }
      `}
    >
      <div
        css={css`
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          background: ${Colors.white};
        `}
      >
        <Image file={item.logo} />
      </div>
      <div
        className="overlay"
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.85);
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        `}
      >
        {onClick ? (
          <Button
            type="button"
            label="Read Article"
            style="white-border"
            onClick={onClick}
          />
        ) : (
          <LinkButton
            label="Read Article"
            style="white-border"
            external
            pure
            href={href as string}
          />
        )}
      </div>
    </div>
  );
}
