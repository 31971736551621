import React from "react";
import { css } from "styled-components";
import { useWrapper } from "../../../helpers/useWrapper";
import * as types from "../../../types/Canvas";
import { Spacing } from "../../../helpers/layout";
import { Image } from "../../Image";
import { Colors } from "../../../branding";
import { EditorContent } from "../../EditorContent";

function MobileCanvasComponent(props: { item: types.CanvasComponent }) {
  const { item } = props;

  return (
    <div
      css={css`
        position: relative;
        text-align: center;

        p > span {
          color: ${Colors.black} !important;
        }
      `}
    >
      <EditorContent text={item.text} responsive blackButtons />
    </div>
  );
}

export function MobileCanvas(props: {
  canvas: types.Canvas;
  proportion: number;
}) {
  const { canvas } = props;
  const components = canvas.components.sort((a, b) => {
    const posA = a.position.x * a.position.y;
    const posB = b.position.x * b.position.y;

    if (posA < posB) {
      return -1;
    }

    if (posA > posB) {
      return 1;
    }

    return 0;
  });

  const spacing = useWrapper();

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        text-align: center;
      `}
    >
      <div>
        <Image file={canvas.image.value} width={600} />
      </div>
      {components.length > 0 && (
        <div
          css={css`
            padding: ${Spacing.l} ${spacing};

            p {
              text-align: inherit !important;

              &:last-child {
                margin-bottom: 0;
              }
            }
          `}
        >
          {components.map(item => {
            return <MobileCanvasComponent item={item} key={item.id} />;
          })}
        </div>
      )}
    </div>
  );
}
