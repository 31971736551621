import React from "react";
import { ImageTextBlock } from "../../types/Block";
import { EditorContent } from "../EditorContent";
import { css } from "styled-components";
import { Image } from "../Image";
import { getImageUrl } from "../../helpers/getImageUrl";
import { Column, Spacing, Row } from "../../helpers/layout";
import { Wrapper } from "../Wrapper";
import { useResponsive } from "../../context/ResponsiveContext";
import { useWrapper } from "../../helpers/useWrapper";
import { Colors } from "../../branding";

export function ImageTextBlockRenderer(props: { block: ImageTextBlock }) {
  const { padding, layout, text, image, bg_color, bg_opacity, media_size } =
    props.block.content;

  const { isMobile, isTablet, renderPadding } = useResponsive();
  const spacing = useWrapper();
  const mediaSize = `${media_size}%`;
  const textWidth = layout === "fullsize" ? "50%" : "30%";
  const textContent = (
    <div
      css={
        isMobile &&
        css`
          color: ${Colors.black};

          span {
            color: inherit !important;
          }

          p {
            text-align: center !important;
          }

          img {
            margin: 0 auto;
            float: none !important;
            display: block;
            margin-bottom: -1rem;
          }
        `
      }
    >
      {React.isValidElement(text) ? (
        text
      ) : (
        <EditorContent text={text} responsive />
      )}
    </div>
  );
  const rowSpacing = isTablet ? Spacing.l : Spacing.xxl;

  const imageContent = (
    <div
      css={css`
        background-color: ${bg_color};
      `}
    >
      <Image
        file={image ? image.value : null}
        style={{
          width: "100%",
          opacity: bg_opacity,
        }}
        width={1000}
      />
    </div>
  );

  if (isMobile) {
    return (
      <Column>
        <div>{imageContent}</div>
        <div
          css={css`
            padding: ${spacing};
            box-sizing: border-box;
          `}
        >
          {textContent}
        </div>
      </Column>
    );
  }

  if (layout === "leftside") {
    return (
      <div
        css={css`
          ${padding && renderPadding(padding)};
        `}
      >
        <Row align="center" gutter={rowSpacing}>
          <div
            css={css`
              width: ${mediaSize};
              flex: 0 0 ${mediaSize};
            `}
          >
            {imageContent}
          </div>
          <div
            css={css`
              flex: 1 0 ${textWidth};
              width: ${textWidth};
              text-align: center;
            `}
          >
            {textContent}
          </div>
        </Row>
      </div>
    );
  }

  if (layout === "rightside") {
    return (
      <div
        css={css`
          ${padding && renderPadding(padding)};
        `}
      >
        <Row align="center" gutter={rowSpacing}>
          <div
            css={css`
              flex: 1 0 ${textWidth};
              width: ${textWidth};
              text-align: center;
            `}
          >
            {textContent}
          </div>
          <div
            css={css`
              width: ${mediaSize};
              flex: 0 0 ${mediaSize};
            `}
          >
            {imageContent}
          </div>
        </Row>
      </div>
    );
  }

  return (
    <div
      css={css`
        position: relative;
        background-color: ${bg_color};
        ${padding && renderPadding(padding)};
      `}
    >
      <div
        css={css`
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-image: ${layout === "fullsize"
            ? `url(${getImageUrl(image ? image.value : null, {
                width: "large",
              })})`
            : "none"};
          background-repeat: no-repeat;
          opacity: ${bg_opacity || 1};
          width: 100%;
          background-size: cover;
          background-position: center center;
          box-sizing: border-box;
        `}
      ></div>
      <div
        css={css`
          position: relative;
        `}
      >
        <Wrapper>{textContent}</Wrapper>
      </div>
    </div>
  );
}
