import React from "react";
import { Image } from "../components/Image";
import { ProductTitle, ProductSerie } from "../typography";
import { Spacing, Column, Row } from "../helpers/layout";
import { InHome } from "../types/InHome";

import { css } from "styled-components";
import { Button } from "./Button";
import { getImageUrl } from "../helpers/getImageUrl";
import { useResponsive } from "../context/ResponsiveContext";

export function InHomeThumb(props: {
  item: Pick<InHome, "image" | "name" | "slug" | "products">;
  height?: number;
  onClick: () => void;
}) {
  const { isMobile } = useResponsive();
  const { item, height } = props;
  const series = item.products.reduce((series: string[], connection) => {
    if (
      connection.product.serie &&
      !series.includes(connection.product.serie.name)
    ) {
      return [...series, connection.product.serie.name];
    }

    return series;
  }, []);

  if (!item.image) return null;

  return (
    <article
      css={css`
        position: relative;
        overflow: hidden;
        transition: box-shadow 400ms;

        ${!isMobile
          ? `
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0);
          .dropdown {
            position: absolute;
            bottom: -150px;
            opacity: 0;
            visibility: hidden;
            transition: all 400ms;
          }

          &:hover {
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.36);

            .dropdown {
              bottom: 0;
              opacity: 1;
              visibility: visible;
            }
          }
        `
          : ""}
      `}
    >
      {height ? (
        <Image
          file={item.image}
          style={{
            height: height,
            maxWidth: "none",
            width: "auto",
            display: "block",
          }}
          height={height}
        />
      ) : (
        <div
          css={css`
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: ${(item.image.height / item.image.width) * 100}%;
            background-image: url(${getImageUrl(item.image, { width: 500 })});
            background-size: 100% 100%;
            background-repeat: no-repeat;
          `}
        />
      )}
      <div
        className="dropdown"
        css={css`
          width: 100%;
          padding: ${Spacing.l};
          box-sizing: border-box;
          ${isMobile
            ? `padding-left: 0; padding-right: 0;`
            : `
            padding-top: ${Spacing.xxl};
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(193, 193, 193, 0.76) 46%,
              #ffffff 100%
            );
          `}
        `}
      >
        <Row align="center">
          <div
            css={css`
              flex: 1 0 10%;
            `}
          >
            <Column gutter={Spacing.xs}>
              <ProductTitle>{item.name}</ProductTitle>
              <ProductSerie>{series.join(", ")}</ProductSerie>
            </Column>
          </div>
          <div
            css={css`
              flex: 0 0 auto;
            `}
          >
            <Button
              type="button"
              label="Learn more"
              onClick={() => {
                props.onClick();
              }}
            />
          </div>
        </Row>
      </div>
    </article>
  );
}
