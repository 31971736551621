import { Media } from "../types/Media";

export type Width = number | "large";

export function getFileUrl(file: Media | null) {
  if (!file) return "";

  const url = `${process.env.REACT_APP_MEDIA_URL}/${file.file_path}`;

  return url;
}
