import React from "react";
import { css } from "styled-components";
import { Visibility } from "../../../helpers/useVisibility";
import { useResponsive } from "../../../context/ResponsiveContext";
import { hexToRgb } from "../../../helpers/utils";
import { CANVAS_LEVEL } from "../../../helpers/zIndex";
import { CanvasComponent } from "./CanvasComponent";
import { Canvas } from "../../../types/Canvas";

const protectNumber = (value: number, min: number, max: number) => {
  if (value < min) {
    return min;
  }

  if (value > max) {
    return max;
  }

  return value;
};

export function CanvasTextParallax(props: {
  canvas: Canvas;
  isVisible: boolean;
  visibility: Visibility;
  proportion: number;
  imageUrl: string;
}) {
  const { isVisible, visibility, canvas, imageUrl } = props;

  const { align, animation, parallax, bg_opacity } = canvas;
  const components = canvas.components.sort((a, b) => {
    if (a.position.y < b.position.y) {
      return -1;
    }

    if (a.position.y > b.position.y) {
      return 1;
    }

    return 0;
  });

  const bg_color = canvas.bg_color || "#ffffff";

  const { sizeCoefficient } = useResponsive();
  const color = hexToRgb(bg_color);
  const initialOpacity = 1;
  const viewed = protectNumber(visibility.viewedPercentage * 3, 0, 1);
  const finalOpacity = Number(bg_opacity);
  const opacityStep = finalOpacity - initialOpacity;
  const divOpacity = initialOpacity + viewed * opacityStep;

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: 150vh;
        background: rgba(${color.r}, ${color.g}, ${color.b}, 1);
      `}
    >
      <div
        css={css`
          position: sticky;
          width: 100%;
          height: 100vh;
          background: url(${imageUrl});
          background-position: top center;
          background-size: cover;
          background-repeat: no-repeat;
          top: 0;
          z-index: 0;
          will-change: opacity;
        `}
        style={{
          opacity: divOpacity,
        }}
      />
      <div
        css={css`
          position: absolute;
          width: 100%;
          height: 100vh;
          bottom: 0;
          left: 0;
          z-index: ${CANVAS_LEVEL};
          top: auto;
          bottom: 0;
        `}
      >
        {components.map((item, index) => {
          return (
            <CanvasComponent
              key={item.id}
              item={item}
              index={index}
              sizeCoefficient={sizeCoefficient}
              animation={isVisible ? animation : "none"}
              align={align}
              visibility={visibility}
              parallax={parallax}
            />
          );
        })}
      </div>
    </div>
  );
}
