const SoundOff = () => (
  <svg width="30px" viewBox="0 0 30 26">
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <path
        d="M16.2081,0.2037 C15.9585,0.0801 15.6609,0.1104 15.4407,0.2796 L6.1392,7.4313 L0.7398,7.4313 C0.3369,7.4313 0.0099,7.758 0.0099,8.1612 L0.0099,17.0394 C0.0099,17.4426 0.3366,17.7693 0.7398,17.7693 L6.1392,17.7693 L15.4416,24.921 C15.5715,25.0209 15.7281,25.0722 15.8862,25.0722 C15.996,25.0722 16.1064,25.0476 16.2084,24.9969 C16.4577,24.8739 16.6158,24.6201 16.6158,24.3423 L16.6152,0.8583 C16.6155,0.5805 16.4574,0.3261 16.2081,0.2037 Z M6.8319,16.4604 C6.7047,16.3626 6.5481,16.3095 6.387,16.3095 L1.4697,16.3095 L1.4697,8.8908 L6.3873,8.8908 C6.5484,8.8908 6.705,8.8377 6.8325,8.7399 L15.156,2.34 L15.1566,22.8603 L6.8319,16.4604 Z"
        fill="#fff"
        fillRule="nonzero"
      ></path>
      <g
        transform="translate(24.000000, 12.500000) rotate(45.000000) translate(-24.000000, -12.500000) translate(19.000000, 8.000000)"
        stroke="#fff"
        strokeLinecap="square"
      >
        <line x1="1.0220652" y1="4.5" x2="9.49265343" y2="4.5"></line>
        <line
          x1="0.757359313"
          y1="4.5"
          x2="9.75735931"
          y2="4.5"
          transform="translate(5.257359, 4.500000) rotate(90.000000) translate(-5.257359, -4.500000) "
        ></line>
      </g>
    </g>
  </svg>
);
export default SoundOff;
