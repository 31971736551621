import React, { useRef } from "react";
import { TabsBlock, TabContent } from "../../types/Block";
import { useState } from "react";
import { Row, Column, Spacing } from "../../helpers/layout";
import { Wrapper } from "../Wrapper";
import { css } from "styled-components";
import { Colors } from "../../branding";
import { ProductTitle, Section, ItalicTitle } from "../../typography";
import { EditorContent } from "../EditorContent";
import { useResponsive } from "../../context/ResponsiveContext";
import ArrowRight from "../../icons/ArrowRight";

const Layout = (props: { children: React.ReactNode | React.ReactNode[] }) => {
  const { isMobile, isTablet } = useResponsive();

  if (isMobile || isTablet) {
    return <Column gutter={Spacing.none}>{props.children}</Column>;
  }

  return <Row gutter={Spacing.xxl}>{props.children}</Row>;
};

function TabContentRenderer(props: { activeTab: TabContent }) {
  const { isMobile, isTablet } = useResponsive();
  const isSmall = isMobile || isTablet;

  return (
    <div>
      {props.activeTab.note && (
        <ProductTitle
          css={css`
            margin-top: ${isSmall ? Spacing.l : Spacing.xl};
            font-size: 18px;
            text-transform: none;
          `}
        >
          {props.activeTab.note}
        </ProductTitle>
      )}
      <Layout>
        {props.activeTab.columns.map((column, idx) => {
          return (
            <div
              key={idx}
              css={css`
                padding-top: ${isSmall ? Spacing.l : Spacing.xl};
                flex: 1;
              `}
            >
              <Column>
                {column.title && (
                  <ItalicTitle
                    css={
                      !isMobile &&
                      !isTablet &&
                      css`
                        font-size: 36px;
                      `
                    }
                  >
                    {column.title}
                  </ItalicTitle>
                )}
                <div
                  css={css`
                    box-sizing: border-box;
                    p {
                      margin-bottom: 30px;
                    }
                    ul {
                      padding-left: 20px;
                    }

                    ol {
                      list-style: none;
                      counter-reset: custom-counter;
                      padding: 0;

                      li {
                        counter-increment: custom-counter;
                        font-size: 16px;
                        line-height: 27px;
                        margin-bottom: 20px;
                        :before {
                          content: counter(custom-counter) ". ";
                          font-size: 18px;
                          font-weight: 700;
                          line-height: 22px;
                        }
                      }
                    }
                  `}
                >
                  <EditorContent text={column.text} />
                </div>
              </Column>
            </div>
          );
        })}
      </Layout>
    </div>
  );
}

export function TabsDesktopBlockRenderer(props: { block: TabsBlock }) {
  const content = props.block.content;
  const [activeTab, setTabActive] = useState<TabContent>(content.tabs[0]);

  return (
    <Wrapper>
      <div
        css={css`
          padding: ${Spacing.xxl} 0;
        `}
      >
        <Row gutter={Spacing.xl}>
          {content.tabs.map((tab, index) => {
            return (
              <React.Fragment key={`${tab.name}`}>
                <div
                  css={css`
                    border-bottom: ${activeTab.name === tab.name
                      ? `2px solid ${Colors.black}`
                      : "none"};
                    padding: 10px 0;
                    cursor: pointer;
                  `}
                  onClick={() => setTabActive(tab)}
                >
                  <Section number={index + 1}>{tab.name}</Section>
                </div>
              </React.Fragment>
            );
          })}
        </Row>
        {activeTab && <TabContentRenderer activeTab={activeTab} />}
      </div>
    </Wrapper>
  );
}

export function MobileTab(props: {
  tab: TabContent;
  index: number;
  onClick: () => void;
  isActive: boolean;
}) {
  const { tab, isActive } = props;
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref}>
      <div
        css={css`
          position: relative;
          border-bottom: 2px solid ${Colors.black};
          padding: 10px 0;
          padding-right: 40px;
          cursor: pointer;
        `}
        onClick={() => {
          props.onClick();

          setTimeout(() => {
            if (ref.current) {
              window.scrollTo({
                top: ref.current.offsetTop - 70,
              });
            }
          }, 50);
        }}
      >
        <Section number={props.index}>{tab.name}</Section>
        <div
          css={css`
            position: absolute;
            right: 6px;
            top: 7px;
            transform: rotate(90deg);
            transition: transform 300ms;

            ${isActive &&
            css`
              transform: rotate(270deg);
            `}

            svg {
              width: 7px;
            }
          `}
        >
          <ArrowRight />
        </div>
      </div>
      <div
        css={css`
          height: auto;
          max-height: 0;
          overflow: hidden;

          ${isActive &&
          css`
            transition: all 500ms;
            max-height: 3000px;
          `}
        `}
      >
        <TabContentRenderer activeTab={tab} />
      </div>
    </div>
  );
}

export function TabsMobileBlockRenderer(props: { block: TabsBlock }) {
  const content = props.block.content;
  const [activeTab, setTabActive] = useState<number | null>(null);

  return (
    <Wrapper>
      <div
        css={css`
          padding: ${Spacing.l} 0;
        `}
      >
        <Column gutter={Spacing.m}>
          {content.tabs.map((tab, index) => {
            return (
              <MobileTab
                isActive={activeTab === index}
                key={`${tab.name}`}
                tab={tab}
                index={index + 1}
                onClick={() => {
                  if (activeTab === index) {
                    setTabActive(null);
                  } else {
                    setTabActive(index);
                  }
                }}
              />
            );
          })}
        </Column>
      </div>
    </Wrapper>
  );
}
export function TabsBlockRenderer(props: { block: TabsBlock }) {
  const { isMobile, isTablet } = useResponsive();

  if (isMobile || isTablet) {
    return <TabsMobileBlockRenderer block={props.block} />;
  }

  return <TabsDesktopBlockRenderer block={props.block} />;
}
