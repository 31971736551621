import React from "react";
import { HrBlock } from "../../types/Block";
import { Wrapper } from "../Wrapper";
import { Hr } from "../Hr";

export function HrBlockRenderer(props: { block: HrBlock }) {
  return (
    <Wrapper>
      <Hr size={props.block.content.size} align="center" />
    </Wrapper>
  );
}
