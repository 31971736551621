const ArrowDown = () => (
  <svg viewBox="0 0 100 125" x="0px" y="0px">
    <polygon
      data-name="Path"
      points="69.6 59.2 52 76.7 52 12.8 48 12.8 48 76.7 30.4 59.2 27.6 62.1 50 84.4 72.4 62.1 69.6 59.2"
    />
  </svg>
);

export default ArrowDown;
