const LocationIcon = () => (
  <svg version="1.1" width="21" viewBox="0 0 100 125">
    <rect width="100%" height="100%" x="0" y="0" fill="none" stroke="none" />
    <path
      d="m49.999925,5.986097382812545 c-15.91074,0 -28.81245,13.29038 -28.81245,29.5625 c0,3.35724 1.42439,7.56391 3.53125,12.59372 c2.10686,5.0299 4.95882,10.751 7.96875,16.4688 c6.01985,11.4355 12.75245,22.9304 15.53125,28.3125 a2.0002,2.0002 0 0 0 3.5625,0 c2.7788,-5.3821 9.5113,-16.877 15.5313,-28.3125 c3.0099,-5.7178 5.8306000000000004,-11.4389 7.9374,-16.4688 c2.1069,-5.02981 3.5626,-9.23648 3.5626,-12.59372 c0,-16.27125 -12.8925,-29.5625 -28.8126,-29.5625 zm0,16.25 c5.6812000000000005,0 10.3126,4.65494 10.3126,10.34375 c0,5.68116 -4.6314,10.34375 -10.3126,10.34375 c-5.6811,0 -10.3437,-4.66259 -10.3437,-10.34375 c0,-5.68881 4.6626,-10.34375 10.3437,-10.34375 z"
      fill="#ffffff"
      fillOpacity="1"
      fillRule="evenodd"
      stroke="none"
      visibility="visible"
      display="inline"
      overflow="visible"
    />
  </svg>
);
export default LocationIcon;
